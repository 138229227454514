import { ShareTwoTone } from "@mui/icons-material";
import React, { useState } from "react";
import useTranslationService from "../../services/TranslationService";
import { IPlace } from "../../stuff/AppModelTypes";
import { SmartChip } from "../chip/SmartChip";
import SmartDialog from "../dialogs/SmartDialog";
import { IDialogProps } from "../dialogs/IDialogProps";
import { IForm } from "../forms/IForm";
import { Stack } from "@mui/material";
import SmartTooltip from "../others/SmartTooltip";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { APP_NAME } from "../../stuff/AppConstants";

const ICON_SIZE = 24;
const ICON_ROUNDED = true;

type Props = {
  place: IPlace;
};

export function ShareChip({ place }: Props) {
  const { tCapitalize } = useTranslationService();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const placeId = place.Id;
  const text = tCapitalize("place") + " " + placeId;
  function _contentCreator(props: IForm) {
    let shareUrl = window.location.origin + "/place/" + placeId;
    let subject = "Stellplatz bei " + APP_NAME + ": " + place.Name;
    const whatsApp = (
      <SmartTooltip title="Teilen mit WhatsApp">
        <WhatsappShareButton title={subject} url={shareUrl}>
          <WhatsappIcon size={ICON_SIZE} round={ICON_ROUNDED} />
        </WhatsappShareButton>
      </SmartTooltip>
    );
    const facebook = (
      <SmartTooltip title="Teilen mit Facebook">
        <FacebookShareButton quote={subject} url={shareUrl}>
          <FacebookIcon size={ICON_SIZE} round={ICON_ROUNDED} />
        </FacebookShareButton>
      </SmartTooltip>
    );
    const twitter = (
      <SmartTooltip title="Teilen mit Twitter">
        <TwitterShareButton title={subject} url={shareUrl}>
          <TwitterIcon size={ICON_SIZE} round={ICON_ROUNDED} />
        </TwitterShareButton>
      </SmartTooltip>
    );
    const linkedin = (
      <SmartTooltip title="Teilen mit LinkedIn">
        <LinkedinShareButton title={subject} source={shareUrl} url={shareUrl}>
          <LinkedinIcon size={ICON_SIZE} round={ICON_ROUNDED} />
        </LinkedinShareButton>
      </SmartTooltip>
    );
    const email = (
      <SmartTooltip title="Teilen per E-Mail">
        <EmailShareButton subject={subject} url={shareUrl}>
          <EmailIcon size={ICON_SIZE} round={ICON_ROUNDED} />
        </EmailShareButton>
      </SmartTooltip>
    );
    const content = (
      <Stack
        direction="row"
        flexWrap="wrap"
        useFlexGap
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ px: 1, pt: 1, pb: 0.5 }}
      >
        {whatsApp}
        {facebook}
        {twitter}
        {linkedin}
        {email}
      </Stack>
    );
    return { content };
  }
  function handleDialogOk(data: any) {}
  function handleDialogClose() {
    setDialogOpen(false);
  }
  const dialogProps: IDialogProps = {
    title: "Platz teilen",
    onCreateContent: _contentCreator,
    onOk: handleDialogOk,
    onClose: handleDialogClose,
  };

  function _handleMouse(eObj: any) {
    if (dialogProps != null) {
      if (!isDialogOpen) {
        setDialogOpen(true);
      }
    }
  }

  const chip = (
    <SmartChip
      icon={<ShareTwoTone />}
      text={text}
      tooltip={text}
      onClick={_handleMouse}
    />
  );
  return (
    <>
      <SmartDialog dialogProps={dialogProps} open={isDialogOpen} />
      {chip}
    </>
  );
}
