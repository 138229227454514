import React from "react";
import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import Kachelmenu from "../components/Kachelmenu";
import AuthenticationGuard from "../components/auth/AuthenticationGuard";
import { ErrorPage } from "../pages/ErrorPage";
import { MyPlacelistPage } from "../pages/MyPlacelistPage";
import PageWrapper from "../pages/PageWrapper";
import { PlaceDetailsPage } from "../pages/PlaceDetailsPage";
import { MapPage } from "../parts/content/MapPage";
import { IMenuEntry, MENU_STRUCTURE } from "./AppConstants";
import { NewPlacesSincePage } from "../pages/news_n_updates/NewPlacesSincePage";
import { UpdatedPlacesSincePage } from "../pages/news_n_updates/UpdatedPlacesSincePage";
import { UpdatedScrapeLinksSincePage } from "../pages/news_n_updates/UpdatedScrapeLinksSincePage";
import { NewScrapeLinksSincePage } from "../pages/news_n_updates/NewScrapeLinksSincePage";
import { LastScrapedScrapeLinksSincePage } from "./../pages/news_n_updates/LastScrapedScrapeLinksSincePage";

const routerChildren = [
  {
    path: "",
    element: <PageWrapper pageTitleKey="main-menu" page={<Kachelmenu />} />,
  },
  {
    path: "menu",
    element: <PageWrapper pageTitleKey="main-menu" page={<Kachelmenu />} />,
  },
  {
    path: "map/:id",
    element: <PageWrapper pageTitleKey="map" page={<MapPage />} />,
  },
  {
    path: "place/:id",
    element: <PageWrapper page={<PlaceDetailsPage />} />,
  },
  {
    path: "placelist/:id",
    element: <PageWrapper page={<MyPlacelistPage />} />,
  },
  {
    path: "newplacessince/:seconds",
    element: <PageWrapper page={<NewPlacesSincePage />} />,
  },
  {
    path: "updatedplacessince/:seconds",
    element: <PageWrapper page={<UpdatedPlacesSincePage />} />,
  },
  {
    path: "newscrapelinkssince/:seconds",
    element: <PageWrapper page={<NewScrapeLinksSincePage />} />,
  },
  {
    path: "updatedscrapelinkssince/:seconds",
    element: <PageWrapper page={<UpdatedScrapeLinksSincePage />} />,
  },
  {
    path: "lastscrapedscrapelinkssince/:seconds",
    element: <PageWrapper page={<LastScrapedScrapeLinksSincePage />} />, //todo
  },
];

for (let i = 0; i < MENU_STRUCTURE.length; i++) {
  const element = MENU_STRUCTURE[i];
  const child = createRouterChildFromMenuEntry(element);
  if (child != null) {
    routerChildren.push(child);
  }
}

export const AppBrowserRouter = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: routerChildren,
  },
]);

//////////////////////////////////////////

function createRouterChildFromMenuEntry(entry: IMenuEntry) {
  if (entry == null || entry.link == null || entry.react == null) {
    return null;
  }
  const comp = <PageWrapper pageTitleKey={entry.nameKey} page={entry.react} />;
  if (entry.authNeeded === true) {
    return {
      path: entry.link,
      element: (
        <AuthenticationGuard
          component={() => {
            return comp;
          }}
        />
      ),
    };
  } else {
    return {
      path: entry.link,
      element: comp,
    };
  }
}
