import { findFlagUrlByIso2Code } from "country-flags-svg";
import React from "react";
import useTranslationService from "../../services/TranslationService";
import { SmartChip } from "../chip/SmartChip";

type Props = {
  countryCode: string;
};

export function CountryChip({ countryCode }: Props) {
  const { tCountryNameFromCountryCode } = useTranslationService();
  const countryName = tCountryNameFromCountryCode(countryCode);
  return (
    <SmartChip
      text={countryName}
      avatarSrc={findFlagUrlByIso2Code(countryCode)}
    />
  );
}
