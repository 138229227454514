import { PlacelistsListWithFilter } from "../components/placelists/PlacelistsListWithFilter";
import { StyledContent } from "../components/styles/styled.Content";

export function PlacelistsListPage() {
  return (
    <StyledContent>
      <PlacelistsListWithFilter />
    </StyledContent>
  );
}
