import { useEffect, useState } from "react";
import { IPlace } from "../stuff/AppModelTypes";
import { useGetScrapeLinksQuery } from "./../api/PlacesApiSlice";
import { SKIP_PLACE } from "./PlaceService";

export function useGetScrapeLinksForPlace(place: IPlace) {
  const [scrapeLinks, setScrapeLinks] = useState<any[] | undefined>(
    place.ScrapeLinks
  );
  const skipFetch = SKIP_PLACE === place || scrapeLinks != null;
  const data = useGetScrapeLinksQuery(place.Id, { skip: skipFetch });

  useEffect(() => {
    if (data != null) {
      if (data.records != null) {
        setScrapeLinks(data.records);
      }
    }
  }, [data]);

  return scrapeLinks;
}
