import React from "react";
import { FRESH_WATER_CONNECTION } from "../../../api/FeaturesConstants";
import { FeatureChip, FeatureChipSubtypeProps } from "./FeatureChip";

export function FreshWaterConnectionChip({ feature }: FeatureChipSubtypeProps) {
  return (
    <FeatureChip
      key={"alkfjaöslföa"}
      feature={feature}
      featureConst={FRESH_WATER_CONNECTION}
    />
  );
}
