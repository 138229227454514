import { Box, TextField } from "@mui/material";
import React from "react";

const InputComponent = React.forwardRef((props, ref) => (
  <Box {...props} ref={ref} />
));
const SmartLabeledField = ({ label, children }) => {
  return (
    <TextField
      variant="outlined"
      label={label}
      multiline
      fullWidth
      InputLabelProps={{ shrink: true }}
      InputProps={{
        inputComponent: InputComponent,
      }}
      inputProps={{ children: children }}
    />
  );
};
export default SmartLabeledField;

//https://codesandbox.io/s/jlmp5kw30w?file=/src/OutlinedDiv.js:0-481
