import React from "react";
import PlacesResultList from "../../components/places/PlacesResultList";

const MAX_NUMBER = 50;

export function NewestPlacesPage() {
  // const { t } = useTranslationService();
  const queryArgs = { order: "created_at,desc", maxResults: MAX_NUMBER };
  return <PlacesResultList queryArguments={queryArgs} />;
}
