import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { TitleContext } from "../../TitleContext";
import PlacesResultList from "../../components/places/PlacesResultList";
import { StyledContent } from "./../../components/styles/styled.Content";
import useTranslationService from "../../services/TranslationService";
import { createIsoStringSecondsBeforeNow } from "../../utils/Utils";
import i18n from "./../../i18n";

type Props = {
  titleKey: string;
  queryKey: string;
};

export function AbstractPlacesSincePage(props: Props) {
  const { t, fromMilisecondsToTimeString } = useTranslationService();
  const routerParams = useParams();
  const titleContext = useContext(TitleContext);

  function updateHeader(seconds: number) {
    const time = fromMilisecondsToTimeString(seconds * 1000);
    const header = i18n.format(t(props.titleKey, { time: time }), "capitalize");
    if (titleContext != null) {
      titleContext.setTitle(header);
    }
  }

  let list;
  if (routerParams && routerParams.seconds) {
    const secondsString = routerParams.seconds;
    const seconds = parseInt(secondsString, 10);
    const isoStringSeconds = createIsoStringSecondsBeforeNow(seconds);
    if (titleContext != null) {
      updateHeader(seconds);
    }
    const queryArgs = {
      [props.queryKey]: isoStringSeconds,
    };
    list = (
      <>
        <PlacesResultList
          key={"PlacesResultList-" + secondsString}
          // updateCount={handleCount}
          infoVisibility="collapsed"
          queryArguments={queryArgs}
        />
      </>
    );
  }

  return <StyledContent>{list}</StyledContent>;
}
