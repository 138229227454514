import React, { useEffect, useState } from "react";
import SmartAccordion from "./SmartAccordion";

interface IProps {
  header: string;
  subheader?: string;
  childrenCallback: () => JSX.Element | JSX.Element[];
  defaultExpanded?: boolean;
  disabled?: boolean;
}

function SmartAccordionLazy({
  header,
  subheader,
  childrenCallback,
  defaultExpanded,
  disabled,
}: IProps): JSX.Element | null {
  const [loaded, setLoaded] = useState<boolean>(false);
  if (defaultExpanded == null) {
    defaultExpanded = false;
  }
  const EMPTY_ELEMENT = <p>empty</p>;
  const [children, setChildren] = useState<JSX.Element | JSX.Element[]>(
    EMPTY_ELEMENT
  );

  if (defaultExpanded === true) {
    handleExpand(null);
  }

  useEffect(() => {
    setLoaded(false);
  }, [childrenCallback]);

  function handleExpand(event: any) {
    if (!loaded) {
      const newChildren = childrenCallback();
      setChildren(newChildren);
      setLoaded(true);
    }
    event?.stopPropagation();
  }

  return (
    <SmartAccordion
      key={header + "-" + subheader}
      header={header}
      subheader={subheader}
      defaultExpanded={defaultExpanded}
      onExpand={handleExpand}
      disabled={disabled}
    >
      {children}
    </SmartAccordion>
  );
}

export default SmartAccordionLazy;
