import React from "react";
import useTranslationService from "../../services/TranslationService";
import { IPlacelist } from "../../stuff/AppModelTypes";
import { SmartChip } from "../chip/SmartChip";

type Props = {
  placelist: IPlacelist;
};

export function AccessChip({ placelist }: Props) {
  const { t, tCapitalize } = useTranslationService();
  const access = placelist.Access;
  if (access === "public") {
    return (
      <SmartChip
        text={t("public")}
        tooltip={tCapitalize("this_place_list_is_public")}
      />
    );
  } else {
    return null;
  }
}
