import {
  CloudSyncTwoTone,
  LocationOffTwoTone,
  LockResetTwoTone,
  MyLocationTwoTone,
  TravelExploreTwoTone,
} from "@mui/icons-material";
import { MenuItem, Stack } from "@mui/material";
import { DivIcon, LatLng, LatLngBounds, Point } from "leaflet";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  MapContainer,
  Marker,
  TileLayer,
  Tooltip,
  useMapEvents,
} from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import styled from "styled-components";
import { getPlaces } from "../../api/PlacesApi";
import { retrievePlacesWithCallback } from "../../api/PlacesApiWithCallback";
import usePlaceService from "../../services/PlaceService";
import { FLY_TO_ANIMATION_SETTINGS } from "../../stuff/AppConstants";
import {
  getObjectFromLocalStorage,
  getObjectFromSessionStorage,
  isJSONString,
  isNullOrUndefined,
} from "../../utils/Utils";
import { getLocationOfPlace } from "./../../utils/AppUtils";
import SearchLocationDialog from "./../SearchLocationDialog";
import SmartActionBar from "./../buttons/SmartActionBar";
import {
  INITIAL_FEATURES_OPTIONS,
  INITIAL_PROVIDER_OPTIONS,
  MAX_PRICE_OPTIONS,
} from "./../places/PlacesConstants";
import { StyledContent } from "./../styles/styled.Content";
import { createCrossIcon, createIcon } from "./LeafletUtils";
import MapMarker from "./MapMarker";
import { toLatLng } from "./MapUtils";

const SHOW_LABELS = true;
const MAP_HEIGHT = 400;
const DIM_MARKERS_ON_NAVIGATE = false;
const MARKER_OPACITY_FULL = 1;
const MARKER_OPACITY_REDUCED = 0.3;
const DEFAULT_BOUNDS = {
  west: -14.67954553901101,
  east: 36.824360710989,
  north: 60.5869773007403,
  south: 34.74162916174235,
};

const StyledMapContainer = styled(MapContainer)`
  display: flex;
  flex-direction: column;
  //height: ${(props) => props.height};
  /* height: 500px; */
  /* height: 50vh; */
  height: ${MAP_HEIGHT + 50 + "px"};
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
    height: 667px;
  }
  @media only screen and (min-device-width: 668px) and (max-device-width: 999px) and (orientation: portrait) {
    height: 999px;
  }
  @media only screen and (min-device-width: 1000px) and (max-device-width: 1200px) and (orientation: portrait) {
    height: 1200px;
  }
  .leaflet-popup-content p {
    margin: 0 0;
  }
`;
function LeafletMapComponent({
  storageKeyCurrentBounds,
  onActivePlaceChanged,
}) {
  const { t } = useTranslation();
  const isWideScreen = useRef(window.innerWidth >= 700);
  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState(null);
  const [places, setPlaces] = useState([]);
  const [markers, setMarkers] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [flyToMarker, setFlyToMarker] = useState(null);
  const [fetchNeeded, setFetchNeeded] = useState(true);
  const [activePlace, setActivePlace] = useState(null);
  const [mapNavigationActive, setMapNavigationActive] = useState(false);
  const [currentBounds, setCurrentBounds] = useState(_getInitialBounds());
  const [flyNeeded, setFlyNeeded] = useState(true);
  const flyToPlaceFromStorage = usePlaceService(
    getObjectFromSessionStorage("flyToPlaceId")
  );
  const [flyToPlace, setFlyToPlace] = useState(null);
  const [geo, setGeo] = useState(null);

  navigator.geolocation.getCurrentPosition(function (position) {
    setGeo(position.coords);
  });

  const storedLoadPlacesOnChangingBounds = localStorage.getItem(
    "loadPlacesOnChangingBounds"
  );
  const [loadPlacesOnChangingBounds, setLoadPlacesOnChangingBounds] = useState(
    isNullOrUndefined(storedLoadPlacesOnChangingBounds)
      ? true
      : JSON.parse(storedLoadPlacesOnChangingBounds)
  );
  const storedMaxPlaceCount = parseInt(localStorage.getItem("maxPlaceCount"));
  const [maxPlaceCount] = useState(
    storedMaxPlaceCount ? storedMaxPlaceCount : 100
  );
  const storedShowPlacesWithoutPriceInfo = localStorage.getItem(
    "showPlacesWithoutPriceInfo"
  );
  const [showPlacesWithoutPriceInfo] = useState(
    storedShowPlacesWithoutPriceInfo !== undefined &&
      storedShowPlacesWithoutPriceInfo !== null
      ? JSON.parse(storedShowPlacesWithoutPriceInfo)
      : true
  );
  const storedMaxPrice = localStorage.getItem("maxPrice");
  const [maxPrice, setMaxPrice] = useState(
    storedMaxPrice ? storedMaxPrice : -1
  );
  const storedCountry = localStorage.getItem("country");
  const [country] = useState(storedCountry ? storedCountry : undefined);
  const storedFeatures = localStorage.getItem("features");
  const [features] = useState(
    storedFeatures && isJSONString(storedFeatures)
      ? JSON.parse(storedFeatures)
      : INITIAL_FEATURES_OPTIONS
  );
  const storedProviders = localStorage.getItem("providers");
  const [providers] = useState(
    storedProviders && isJSONString(storedProviders)
      ? JSON.parse(storedProviders)
      : INITIAL_PROVIDER_OPTIONS
  );
  const [searchDialogOpen, setSearchDialogOpen] = useState(false);

  const userLocationIcon = createIcon("/images/pins/user.svg");

  useEffect(() => {
    if (
      flyToPlaceFromStorage != null &&
      flyToPlaceFromStorage.placeObject != null &&
      flyToPlaceFromStorage.placeObject.Longitude != null
    ) {
      setFlyToPlace(flyToPlaceFromStorage.placeObject);
    }
  }, [flyToPlaceFromStorage]);

  // save the settings to local storage
  useEffect(() => {
    localStorage.setItem("maxPlaceCount", maxPlaceCount);
    localStorage.setItem("maxPrice", maxPrice);
    localStorage.setItem(
      "showPlacesWithoutPriceInfo",
      showPlacesWithoutPriceInfo
    );
    const stringifiedProviders = JSON.stringify(providers);
    localStorage.setItem("providers", stringifiedProviders);
    const stringifiedFeatures = JSON.stringify(features);
    localStorage.setItem("features", stringifiedFeatures);
  }, [
    maxPlaceCount,
    maxPrice,
    showPlacesWithoutPriceInfo,
    providers,
    features,
  ]);

  // notify listeners to the change of the active (=selected) place
  useEffect(() => {
    if (onActivePlaceChanged != null) {
      onActivePlaceChanged(activePlace);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePlace]);

  function getCurrentLatLngBounds() {
    let ret = null;
    if (currentBounds != null && currentBounds.north != null) {
      const corner1 = new LatLng(currentBounds.north, currentBounds.east);
      const corner2 = new LatLng(currentBounds.south, currentBounds.west);
      ret = new LatLngBounds(corner1, corner2);
    }
    return ret;
  }

  const url =
    zoom > 18
      ? "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
      : "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
  const dynamicTyleLayer = (
    <TileLayer url={url} maxZoom={21} maxNativeZoom={19} />
  );

  function _completeFetchArgs(args) {
    if (!args.bounds) {
      args.bounds = currentBounds;
    }
  }
  function fetchPlaces(args) {
    function _callbackPlacesFound(places) {
      setPlaces(places);
      setFetchNeeded(false);
    }
    _completeFetchArgs(args);
    retrievePlacesWithCallback(args, _callbackPlacesFound);
    getPlaces(args);
  }
  function _hideAllPlaces() {
    setPlaces([]);
  }
  // function _showTooltips() {
  //   setShowTooltips(true);
  //   setTimeout(function () {
  //     setShowTooltips(false);
  //   }, 5000);
  // }
  function _setMaxPrice(e) {
    if (e && e.target && !isNullOrUndefined(e.target.value)) {
      const c = e.target.value;
      setMaxPrice(c);
      setFetchNeeded(true);
    }
  }

  // fetch the places if something for the filter changes
  useEffect(() => {
    if (fetchNeeded === false) {
      return;
    }
    const fetchArgs = {
      onlyWithLocation: true,
      maxSize: Math.min(10000, maxPlaceCount),
      showPlacesWithoutPriceInfo: showPlacesWithoutPriceInfo,
      maxPrice: maxPrice,
      country: country,
      providers: providers,
      features: features,
      deleted: false,
    };
    fetchPlaces(fetchArgs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    maxPlaceCount,
    showPlacesWithoutPriceInfo,
    maxPrice,
    country,
    providers,
    features,
  ]);

  // fetch the places if bounds change
  useEffect(() => {
    if (currentBounds == null) {
      return;
    }
    if (loadPlacesOnChangingBounds || fetchNeeded) {
      _fetchPlacesInCurrentBounds();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBounds, fetchNeeded]);

  function _flyToLocation(location, fetch) {
    if (flyNeeded && map != null) {
      map.flyTo(toLatLng(location), 16, FLY_TO_ANIMATION_SETTINGS);
      if (fetch) {
        setFetchNeeded(true);
      }
      const ftm = (
        <Marker
          position={[location.latitude, location.longitude]}
          icon={createCrossIcon()}
          opacity={0.4}
        >
          <Tooltip>{t("target")}</Tooltip>
        </Marker>
      );
      setFlyToMarker(ftm);
      // setFlyNeeded(false);
    }
  }

  function _flyToCurrentLocation(fetch) {
    const nav = navigator;
    const geoLoc = nav.geolocation;
    geoLoc.getCurrentPosition(function (position) {
      const geo = position.coords;
      if (geo) {
        const newLatLng = {
          lng: geo.longitude,
          lat: geo.latitude,
        };
        if (!flyNeeded) {
          setFlyNeeded(true);
        }
        _flyToLocation(newLatLng, fetch);
      }
    });
  }
  function _searchLocation() {
    setSearchDialogOpen(true);
  }
  function _fetchPlacesInCurrentBounds() {
    _removePlacesOutOfCurrentBounds();
    const fetchArgs = {
      bounds: currentBounds,
      onlyWithLocation: true,
      maxSize: Math.min(10000, maxPlaceCount),
      showPlacesWithoutPriceInfo: showPlacesWithoutPriceInfo,
      maxPrice: maxPrice,
      country: country,
      providers: providers,
      features: features,
      deleted: false,
    };
    fetchPlaces(fetchArgs);
  }

  // create and set the markers
  useEffect(() => {
    if (mapNavigationActive) {
      return;
    }
    const activePlaceId = activePlace?.Id;
    const m = places.map((p) => (
      <MapMarker
        key={p.Id}
        riseOnHover={true}
        data={p}
        position={[p.Latitude, p.Longitude]}
        opacity={
          DIM_MARKERS_ON_NAVIGATE && mapNavigationActive
            ? MARKER_OPACITY_REDUCED
            : MARKER_OPACITY_FULL
        }
        eventHandlers={{
          click: (e) => {
            setActivePlace(p);
            // if (onActivePlaceChanged != null) {
            //   onActivePlaceChanged(p);
            // }
          },
        }}
        activePlaceId={activePlaceId}
      />
    ));
    setMarkers(m);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePlace, mapNavigationActive, places]);

  useEffect(() => {
    localStorage.setItem(
      "loadPlacesOnChangingBounds",
      loadPlacesOnChangingBounds
    );
  }, [loadPlacesOnChangingBounds]);

  useEffect(() => {
    if (flyNeeded === true && map != null) {
      if (flyToPlace != null) {
        const toLoc = getLocationOfPlace(flyToPlace);
        if (toLoc != null) {
          setActivePlace(flyToPlace);
          if (getObjectFromSessionStorage("flyToPlaceId") != null) {
            sessionStorage.removeItem("flyToPlaceId");
          }
          _flyToLocation(toLoc, fetchNeeded);
        }
      } else {
        const toLoc = getObjectFromSessionStorage("flyToLocation");
        if (toLoc != null) {
          _flyToLocation(toLoc, fetchNeeded);
          sessionStorage.removeItem("flyToLocation");
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flyToPlace, flyNeeded, map]);

  function _removePlacesOutOfCurrentBounds() {
    const currentPlaces = places;
    const currentPlaceCount = currentPlaces.length;
    if (currentPlaceCount >= maxPlaceCount) {
      const newPlaceList = currentPlaces.filter((place) =>
        _isPlaceInBounds(place, currentBounds)
      );
      const newLength = newPlaceList.length;
      if (newLength !== currentPlaceCount) {
        setPlaces(newPlaceList);
      }
    }
  }

  function _isPlaceInBounds(place, bounds) {
    let ret = false;
    const { west, east, north, south } = bounds;
    const latitude = place.Latitude;
    const longitude = place.Longitude;
    if (
      south <= latitude &&
      latitude <= north &&
      west <= longitude &&
      longitude <= east
    ) {
      ret = true;
    }
    return ret;
  }

  function MapListener() {
    const map = useMapEvents({
      click: (e) => {
        setActivePlace(null);
      },
      movestart: (e) => {
        setMapNavigationActive(true);
      },
      moveend: (e) => {
        setMapNavigationActive(false);
        const b = map.getBounds();
        const ne = b._northEast;
        const sw = b._southWest;
        const newBounds = {
          west: sw.lng,
          east: ne.lng,
          north: ne.lat,
          south: sw.lat,
        };
        setCurrentBounds(newBounds);
        if (loadPlacesOnChangingBounds) {
          setFetchNeeded(true);
        }
        if (storageKeyCurrentBounds != null) {
          const stringified = JSON.stringify(newBounds);
          localStorage.setItem(storageKeyCurrentBounds, stringified);
        }
      },
      zoom: () => {
        if (!map) return;
        const zoom = map.getZoom();
        setZoom(zoom);
      },
    });
    return null;
  }

  function _getInitialBounds() {
    let ret = DEFAULT_BOUNDS;
    if (storageKeyCurrentBounds != null) {
      const o = getObjectFromLocalStorage(storageKeyCurrentBounds);
      if (o != null) {
        ret = o;
      }
    }
    return ret;
  }

  function defaultIconCreateFunction(cluster) {
    var childCount = cluster.getChildCount();
    var c = " marker-cluster-";
    if (childCount < 10) {
      c += "small";
    } else if (childCount < 100) {
      c += "medium";
    } else {
      c += "large";
    }
    const point = new Point(40, 40);
    const ret = new DivIcon({
      html: "<div><span>" + childCount + "</span></div>",
      className: "marker-cluster" + c,
      iconSize: point,
    });
    return ret;
  }
  function handleSearchDialogClose(p) {
    setSearchDialogOpen(false);
    if (p && p.geometry && p.geometry.coordinates) {
      const coords = p.geometry.coordinates;
      const latLng = { lng: coords[0], lat: coords[1] };
      _flyToLocation(latLng, true);
    }
  }

  const actions = [
    {
      text: t("show-current-location"),
      shortText: t("location"),
      showLabel: SHOW_LABELS,
      onMouse: () => _flyToCurrentLocation(true),
      tooltipPlacement: "bottom",
      children: <MyLocationTwoTone />,
    },
    {
      text: t("find-location") + "...",
      shortText: t("search") + "...",
      showLabel: SHOW_LABELS,
      onMouse: _searchLocation,
      tooltipPlacement: "top",
      children: <TravelExploreTwoTone />,
    },
    {
      text: t("automatically-update-places"),
      shortText: t("always-update"),
      showLabel: SHOW_LABELS,
      selected: loadPlacesOnChangingBounds,
      onMouse: () => {
        if (!loadPlacesOnChangingBounds) {
          _fetchPlacesInCurrentBounds();
        }
        setLoadPlacesOnChangingBounds(!loadPlacesOnChangingBounds);
      },
      tooltipPlacement: "bottom",
      children: (
        <LockResetTwoTone
          color={loadPlacesOnChangingBounds ? "" : "disabled"}
        />
      ),
    },
    {
      text: t("update-places"),
      shortText: t("update"),
      showLabel: SHOW_LABELS,
      onMouse: _fetchPlacesInCurrentBounds,
      tooltipPlacement: "top",
      children: <CloudSyncTwoTone />,
    },
    {
      text: t("hide-places"),
      shortText: t("hide"),
      showLabel: SHOW_LABELS,
      onMouse: _hideAllPlaces,
      tooltipPlacement: "bottom",
      children: <LocationOffTwoTone />,
    },
    {
      text: t("maximum-price"),
      shortText: t("max-price"),
      showLabel: SHOW_LABELS,
      value: maxPrice,
      onMouse: _setMaxPrice,
      tooltipPlacement: "top",
      children:
        MAX_PRICE_OPTIONS &&
        MAX_PRICE_OPTIONS.map((o) => (
          <MenuItem key={o.key} value={o.value}>
            {o.label}
          </MenuItem>
        )),
    },
  ];
  const toolbar = (
    <SmartActionBar
      actions={actions}
      accordionOpen={isWideScreen.current}
      showLabels={true}
    />
  );

  let mapContainer = null;
  const mapBounds = getCurrentLatLngBounds();
  if (mapBounds != null) {
    mapContainer = (
      <StyledMapContainer
        // style={{ backgroundColor: "var(--bg-color)" }}
        id="this_map"
        scrollWheelZoom={false}
        bounds={mapBounds}
        ref={setMap}
        // whenCreated={(map) => this.setState({ map })}
      >
        <MapListener />
        {dynamicTyleLayer}
        <MarkerClusterGroup
          chunkedLoading={true}
          spiderfyOnMaxZoom={false}
          showCoverageOnHover={true}
          zoomToBoundsOnClick={true}
          iconCreateFunction={defaultIconCreateFunction}
          disableClusteringAtZoom={10}
          // singleMarkerMode={true}
          maxClusterRadius={function (mapZoom) {
            if (mapZoom > 13) {
              return 1;
            }
            if (mapZoom > 12) {
              return 5;
            }
            if (mapZoom > 11) {
              return 10;
            }
            if (mapZoom > 8) {
              return 30;
            }
            if (mapZoom > 6) {
              return 50;
            }
            return 80;
          }}
        >
          {markers}
          {/* {flyToMarker ? flyToMarker : null} */}
        </MarkerClusterGroup>
        {geo != null && (
          <Marker
            // icon={createCrossIcon()}
            icon={userLocationIcon}
            position={toLatLng(geo)}
          />
        )}
      </StyledMapContainer>
    );
  }

  return (
    <StyledContent>
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        spacing={0}
      >
        {toolbar}
        <SearchLocationDialog
          open={searchDialogOpen}
          onClose={handleSearchDialogClose}
        />
        {mapContainer}
      </Stack>
    </StyledContent>
  );
}

export default React.memo(LeafletMapComponent);

///////////////////////////////////////////////
