import { Stack } from "@mui/material";
import { DivIcon, Icon, Point } from "leaflet";
import React, { useEffect, useMemo, useState } from "react";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  Tooltip,
  useMapEvents,
} from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import styled from "styled-components";
import { IPlace } from "../../stuff/AppModelTypes";
import {
  isArray,
  isNullOrUndefined,
  isString,
  numberToMoney,
} from "../../utils/Utils";
import PlaceSmall from "../place/PlaceSmall";
import { StyledContent } from "../styles/styled.Content";
import { fromBoundsToMapBounds } from "./LeafletUtils";
import {
  IBound,
  calculateBoundsAroundPlaces,
  spreadBoundsToMinimum,
} from "./MapUtils";

const MAP_HEIGHT = 400;
const DIM_MARKERS_ON_NAVIGATE = false;
const MARKER_OPACITY_FULL = 1;
const MARKER_OPACITY_REDUCED = 0.3;

const StyledMarker: any = styled(Marker)`
  opacity: MARKER_OPACITY_FULL;
`;
const StyledMapContainer = styled(MapContainer)`
  display: flex;
  flex-direction: column;
  height: ${MAP_HEIGHT + 50 + "px"};
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
    height: 667px;
  }
  @media only screen and (min-device-width: 668px) and (max-device-width: 999px) and (orientation: portrait) {
    height: 999px;
  }
  @media only screen and (min-device-width: 1000px) and (max-device-width: 1200px) and (orientation: portrait) {
    height: 1200px;
  }
`;

interface IProps {
  bounds?: IBound;
  places?: IPlace[];
  onBoundsChanged?: any;
}

function SmartMap(props: IProps) {
  const [map, setMap] = useState<any>(null);
  const [zoom, setZoom] = useState<number | null>(null);
  const [markers, setMarkers] = useState<any[]>([]);
  const [, setActivePlace] = useState<any>(null);
  const [mapNavigationActive, setMapNavigationActive] = useState(false);
  // const [showTooltips, setShowTooltips] = useState(false);
  const currentBounds = useMemo(() => {
    return determineBoundsFromProps(props);
  }, [props]);
  const [mapBounds, setMapBounds] = useState(
    fromBoundsToMapBounds(currentBounds)
  );

  useEffect(() => {
    const mbs = fromBoundsToMapBounds(currentBounds);
    // console.dir(mbs);
    setMapBounds(mbs);
    if (map != null) {
      // console.dir(map);
      map.fitBounds(mbs);
    }
  }, [currentBounds, map]);

  function createTooltip(place: any) {
    let ret;
    const preisText = isNullOrUndefined(place.Preis)
      ? null
      : numberToMoney(place.Preis);
    let domainsText = null;
    if (!isNullOrUndefined(place.Domains)) {
      if (isString(place.Domains) && place.Domains.length > 0) {
        domainsText = place.Domains;
      } else if (isArray(place.Domains) && place.Domains.length > 0) {
        domainsText = place.Domains.join(", ");
      }
    }
    const additionalInfoList = [];
    if (preisText) {
      additionalInfoList.push(preisText);
    }
    if (domainsText) {
      additionalInfoList.push(domainsText);
    }
    if (additionalInfoList.length < 1) {
      ret = place.Name;
    } else {
      ret = place.Name + " (" + additionalInfoList.join(", ") + ")";
    }
    return ret;
  }
  function createIcon(place: any) {
    const preis = place.Preis;
    let imageName = "";
    if (preis === undefined || preis === null) {
      imageName = "gray";
    } else if (preis > 30) {
      imageName = "pink";
    } else if (preis > 20) {
      imageName = "red";
    } else if (preis > 10) {
      imageName = "orange";
    } else if (preis > 0) {
      imageName = "yellow";
    } else if (preis === 0) {
      imageName = "green";
    }
    const iconUrl = "/images/pins/" + imageName + ".svg";
    const ret = new Icon({
      iconUrl: iconUrl,
      iconSize: [38, 95],
      iconAnchor: [19, 65],
      popupAnchor: [0, -40],
    });
    return ret;
  }

  const url =
    zoom != null && zoom > 18
      ? "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
      : "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
  const dynamicTyleLayer = (
    <TileLayer url={url} maxZoom={21} maxNativeZoom={19} />
  );

  useEffect(() => {
    if (props.places == null) {
      return;
    }
    function _checkPlace(place: IPlace) {
      if (place != null && place.Latitude != null && place.Longitude != null) {
        return true;
      }
      return false;
    }
    const m = props.places.filter(_checkPlace).map((p) => (
      <StyledMarker
        key={p.Id}
        position={[p.Latitude, p.Longitude]}
        opacity={
          DIM_MARKERS_ON_NAVIGATE && mapNavigationActive
            ? MARKER_OPACITY_REDUCED
            : MARKER_OPACITY_FULL
        }
        onClick={(event: any) => {
          setActivePlace(p);
          event.stopPropagation();
        }}
        icon={createIcon(p)}
      >
        <Tooltip>{createTooltip(p)}</Tooltip>
        <Popup autoPan={false} closeButton={false}>
          <PlaceSmall key={p.Id} placeObject={p} infoVisibility="hidden" />
        </Popup>
      </StyledMarker>
    ));
    setMarkers(m);
  }, [mapNavigationActive, props]);

  function MapListener() {
    const map = useMapEvents({
      movestart: (e) => {
        setMapNavigationActive(true);
      },
      moveend: (e) => {
        setMapNavigationActive(false);
        const b = map.getBounds();
        const ne = b.getNorthEast();
        const sw = b.getSouthWest();
        const newBounds = {
          west: sw.lng,
          east: ne.lng,
          north: ne.lat,
          south: sw.lat,
        };
        // setCurrentBounds(newBounds);
        if (props.onBoundsChanged) {
          props.onBoundsChanged(newBounds);
        }
      },
      zoom: () => {
        if (!map) return;
        const zoom = map.getZoom();
        setZoom(zoom);
      },
    });
    return null;
  }

  function defaultIconCreateFunction(cluster: { getChildCount: () => any }) {
    var childCount = cluster.getChildCount();
    var c = " marker-cluster-";
    if (childCount < 10) {
      c += "small";
    } else if (childCount < 100) {
      c += "medium";
    } else {
      c += "large";
    }
    const point = new Point(40, 40);
    const ret = new DivIcon({
      html: "<div><span>" + childCount + "</span></div>",
      className: "marker-cluster" + c,
      iconSize: point,
    });
    return ret;
  }

  return (
    <StyledContent>
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        spacing={1}
      >
        <StyledMapContainer
          // style={{ backgroundColor: "var(--bg-color)" }}
          id="this_map"
          scrollWheelZoom={false}
          bounds={mapBounds}
          ref={setMap}
          // whenCreated={(map) => this.setState({ map })}
        >
          <MapListener />
          {dynamicTyleLayer}
          <MarkerClusterGroup
            chunkedLoading={true}
            spiderfyOnMaxZoom={false}
            showCoverageOnHover={true}
            zoomToBoundsOnClick={true}
            iconCreateFunction={defaultIconCreateFunction}
            disableClusteringAtZoom={10}
            maxClusterRadius={function (mapZoom: number) {
              if (mapZoom > 13) {
                return 1;
              }
              if (mapZoom > 12) {
                return 5;
              }
              if (mapZoom > 11) {
                return 10;
              }
              if (mapZoom > 8) {
                return 30;
              }
              if (mapZoom > 6) {
                return 50;
              }
              return 80;
            }}
          >
            {markers}
          </MarkerClusterGroup>
        </StyledMapContainer>
      </Stack>
    </StyledContent>
  );
}

// export default React.memo(SmartMap);
export default SmartMap;

///////////////////////////////////////////////////////////////

function determineBoundsFromProps(props: IProps) {
  let bounds: IBound = { west: -3, east: 28, north: 53, south: 47 };
  if (props.bounds != null) {
    bounds = props.bounds;
  } else {
    if (props.places != null && props.places.length > 0) {
      bounds = calculateBoundsAroundPlaces(props.places);
      bounds = spreadBoundsToMinimum(bounds, 0.01, {
        north: 5,
        west: 1,
        east: 1,
        south: 1,
      });
    }
  }
  return bounds;
}
