import {
  Box,
  createTheme,
  CssBaseline,
  ThemeProvider as MuiThemeProvider,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { closeSnackbar, SnackbarProvider } from "notistack";
import React, { useState } from "react";
import CookieConsent from "react-cookie-consent";
import { Outlet } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import MainDrawer from "./parts/header/MainDrawer";
import MainToolbar from "./parts/header/MainToolbar";
import { StandardTheme } from "./themes/StandardTheme";
import { TitleContext } from "./TitleContext";
import { isDebugInfo } from "./utils/AppUtils";
import { DRAWER_WIDTH } from "./stuff/StyleConstants";
import useTranslationService from "./services/TranslationService";

function App() {
  const { t } = useTranslationService();
  const [title, setTitle] = useState<any | undefined>(undefined);
  const [drawerOpen, setDrawerOpen] = useState(false);
  function toggleDrawer() {
    setDrawerOpen(!drawerOpen);
  }
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const muiTheme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? "dark" : "light",
        },
      }),
    [prefersDarkMode]
  );

  const footerText =
    "Node: " + process.env.NODE_ENV + "  -  App: " + process.env.REACT_APP_ENV;

  return (
    <ThemeProvider theme={StandardTheme}>
      <MuiThemeProvider theme={muiTheme}>
        <TitleContext.Provider value={{ title, setTitle }}>
          <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <MainToolbar toggleDrawer={toggleDrawer} />
            <MainDrawer isOpen={drawerOpen} toggleDrawer={toggleDrawer} />
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                // p: 0,
                width: { sm: `calc(100% - ${DRAWER_WIDTH}px)` },
              }}
            >
              <SnackbarProvider
                autoHideDuration={4000}
                action={(snackbarId) => (
                  <button onClick={() => closeSnackbar(snackbarId)}>
                    {/* {t("dismiss")} */}x
                  </button>
                )}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
              >
                <Toolbar />{" "}
                {/* just to let the Outlet content shift down a bit */}
                <Outlet />
                {isDebugInfo() && (
                  <Typography align="center">{footerText}</Typography>
                )}
              </SnackbarProvider>
            </Box>
          </Box>
        </TitleContext.Provider>
        <CookieConsent location="bottom" expires={999} overlay>
          {t("this-website-uses-cookies-to-enhance-the-user-experience")}{" "}
        </CookieConsent>
      </MuiThemeProvider>
    </ThemeProvider>
  );
}

export default App;
