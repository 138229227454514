import React, { useEffect, useState } from "react";
import SmartAccordion from "../accordion/SmartAccordion";
import PlacesResultList from "./PlacesResultList";

interface IProps {
  header: string;
  queryArguments: any;
}

export function PlacesResultAccordion(props: IProps) {
  const [count, setCount] = useState<number | null>(null);
  const [headerInternal, setHeaderInternal] = useState<string>(
    props.header + " (...)"
  );
  function handleCount(count: number | null): void {
    setCount(count);
  }

  useEffect(() => {
    if (count != null) {
      setHeaderInternal(props.header + " (" + count + ")");
    } else {
      setHeaderInternal(props.header + " (...)");
    }
  }, [count, props.header]);

  return count !== 0 ? (
    <SmartAccordion
      key={props.header}
      header={headerInternal}
      disabled={count === 0}
    >
      <PlacesResultList
        key={"PlacesResultList-" + props.header}
        updateCount={handleCount}
        queryArguments={props.queryArguments}
        infoVisibility="collapsed"
      />
    </SmartAccordion>
  ) : null;
}
