import React from "react";
import { AbstractScrapeLinksSincePage } from "./AbstractScrapeLinksSincePage";

export function LastScrapedScrapeLinksSincePage() {
  return (
    <AbstractScrapeLinksSincePage
      titleKey={"last-scraped-scrape-links-from-the-last-time"}
      queryKey={"fromLastScrape"}
    />
  );
}
