import { DriveFileRenameOutlineTwoTone } from "@mui/icons-material";
import React, { useState } from "react";
import SmartButton from "./../buttons/SmartButton";
import RenameDialog from "./RenameDialog";
import { useTranslation } from "react-i18next";

export interface IRenameComponentProps {
  oldName: string;
  onNewName: (value: string) => void;
  text?: string;
  shortText?: string;
  label?: string;
  showLabel?: boolean;
  disabled?: boolean;
}

function RenameComponent(props: IRenameComponentProps): JSX.Element | null {
  const { t } = useTranslation();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const text = props.text ? props.text : t("rename") + "...";
  const shortText = props.shortText ? props.shortText : t("rename");
  const label = props.label ? props.label : t("name");
  const disabled = props.disabled ? props.disabled : false;

  function handleNewName(p: string) {
    if (props.onNewName) {
      props.onNewName(p);
    }
    setDialogOpen(false);
  }
  function handleClose() {
    setDialogOpen(false);
  }

  return (
    <>
      <SmartButton
        text={text}
        shortText={shortText}
        onMouse={() => setDialogOpen(true)}
        disabled={disabled}
      >
        <DriveFileRenameOutlineTwoTone />
      </SmartButton>
      <RenameDialog
        open={isDialogOpen}
        oldValue={props.oldName}
        onOk={handleNewName}
        onClose={handleClose}
        title={label}
        label={label}
      />
    </>
  );
}

export default RenameComponent;
