import { FilterAltTwoTone } from "@mui/icons-material";
import {
  ButtonGroup,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { isJSONString, isNullOrUndefined } from "../../utils/Utils";
import SmartDialog from "../dialogs/SmartDialog";
import { SmartDialogButton } from "../dialogs/SmartDialogButton";
import { FeatureSettingsForm } from "../settings/FeatureSettingsForm";
import PlacesResultList from "./PlacesResultList";
import {
  INITIAL_FEATURES_OPTIONS,
  INITIAL_PROVIDER_OPTIONS,
  MAX_PRICE_OPTIONS,
} from "./PlacesConstants";

export function PlacesListWithFilter() {
  const { t } = useTranslation();
  const storedCountriesString = localStorage.getItem("countries");
  const storedCountries = JSON.parse(storedCountriesString);
  const [countries] = useState(storedCountries ? storedCountries : []);
  const storedShowPlacesWithoutAttachment = localStorage.getItem(
    "showPlacesWithoutAttachment"
  );
  const [showPlacesWithoutAttachment] = useState(
    storedShowPlacesWithoutAttachment != null
      ? JSON.parse(storedShowPlacesWithoutAttachment)
      : true
  );
  const storedShowPlacesWithoutPriceInfo = localStorage.getItem(
    "showPlacesWithoutPriceInfo"
  );
  const [showPlacesWithoutPriceInfo] = useState(
    storedShowPlacesWithoutPriceInfo != null
      ? JSON.parse(storedShowPlacesWithoutPriceInfo)
      : true
  );
  const storedMaxPrice = localStorage.getItem("maxPrice");
  const [maxPrice, setMaxPrice] = useState(
    storedMaxPrice
      ? storedMaxPrice
      : MAX_PRICE_OPTIONS[MAX_PRICE_OPTIONS.length - 1].value
  );
  const storedProviders = localStorage.getItem("providers");
  const [providers] = useState(
    storedProviders && isJSONString(storedProviders)
      ? JSON.parse(storedProviders)
      : INITIAL_PROVIDER_OPTIONS
  );
  const [features, setFeatures] = useState(_recallFeatures());
  const [searchString, setSearchString] = useState("");
  const [queryProps, setQueryProps] = useState({});

  useEffect(() => {
    localStorage.setItem("maxPrice", maxPrice);
    const props = {
      searchString: searchString,
      countries: countries,
      showPlacesWithoutAttachment,
      showPlacesWithoutPriceInfo,
      maxPrice: maxPrice,
      providers: providers,
      features: features,
      joinScrapeLinks: true,
      deleted: false,
    };
    setQueryProps(props);
  }, [
    searchString,
    showPlacesWithoutAttachment,
    showPlacesWithoutPriceInfo,
    maxPrice,
    providers,
    features,
    countries,
  ]);

  let list = <Typography>{t("loading")}</Typography>;
  if (!isNullOrUndefined(queryProps) && Object.keys(queryProps).length > 0) {
    list = <PlacesResultList queryArguments={queryProps} />;
  }

  function _contentCreator(props) {
    const content = <FeatureSettingsForm />;
    return { content };
  }
  const featuresDialogProps = {
    title: "Features",
    onCreateContent: _contentCreator,
    onClose: () => {
      setFeatures(_recallFeatures());
    },
  };

  return (
    <>
      <ButtonGroup>
        <TextField
          id="outlined-basic"
          label={t("search-in-name")}
          value={searchString}
          type="search"
          onChange={(e) => setSearchString(e.target.value)}
        />
        <FormControl fullWidth>
          <InputLabel id="select-maxPrice-label">
            {t("maximum-price")}
          </InputLabel>
          <Select
            labelId="select-maxPrice-label"
            id="select-maxPrice"
            value={maxPrice}
            label={t("maximum-price")}
            onChange={(e) => setMaxPrice(e.target.value)}
          >
            {MAX_PRICE_OPTIONS &&
              MAX_PRICE_OPTIONS.map((o) => (
                <MenuItem key={o.key} value={o.value}>
                  {o.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <SmartDialogButton
          dialogProps={featuresDialogProps}
          text={"Filtern for Features"}
          shortText={"Features"}
          showLabel={false}
        >
          <FilterAltTwoTone />
        </SmartDialogButton>
        <SmartDialog />
      </ButtonGroup>
      {list}
    </>
  );
}

//--------------------------------------------------

function _recallFeatures() {
  const storedFeatures = localStorage.getItem("features");
  if (storedFeatures != null && isJSONString(storedFeatures)) {
    return JSON.parse(storedFeatures);
  } else {
    return INITIAL_FEATURES_OPTIONS;
  }
}
