import React, { useEffect, useState } from "react";
import {
  useGetAllPlaceAttachmentsQuery,
  useGetPlacesJoinFilteredQuery,
} from "../../api/PlacesApiSlice.js";
import { useUserService } from "../../services/UserService";
import { SmartQueryMap } from "../maps/SmartQueryMap";

interface IProps {
  queryArguments: any;
  maxNumber?: number;
  fromLatiude?: number;
  fromLongitude?: number;
  updateCount?: (count: null | number) => void;
}

export default function PlacesResultMap({
  queryArguments,
  maxNumber,
  fromLatiude,
  fromLongitude,
  updateCount,
}: IProps): JSX.Element | null {
  const [queryArgs, setQueryArgs] = useState<null | any>();
  const { placeAttachmentsData } = useGetAllPlaceAttachmentsQuery();
  const { isSuperuser, isServiceReady } = useUserService();
  const [maxPlaceCount, setMaxPlaceCount] = useState<number>();

  useEffect(() => {
    if (maxNumber != null) {
      setMaxPlaceCount(maxNumber);
    } else {
      const fromLocalStorage = localStorage.getItem("maxPlaceCount");
      if (fromLocalStorage != null) {
        const num = parseInt(fromLocalStorage);
        if (num != null) {
          setMaxPlaceCount(num);
        }
      }
      if (maxPlaceCount == null) {
        setMaxPlaceCount(100);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxNumber]);

  useEffect(() => {
    if (isServiceReady) {
      const qaTemp = { ...queryArguments };
      if (queryArguments != null && placeAttachmentsData != null) {
        const specialHandling =
          isSuperuser && queryArguments.showPlacesWithoutAttachment === false;
        if (specialHandling) {
          const placeIdList = [];
          const placeAttachments = placeAttachmentsData.records;
          for (let index = 0; index < placeAttachments.length; index++) {
            const ap = placeAttachments[index];
            placeIdList.push(ap.PlaceId);
          }
          if (queryArguments.ids == null) {
            qaTemp.ids = placeIdList;
          }
        }
      }
      setQueryArgs(qaTemp);
    }
  }, [isSuperuser, placeAttachmentsData, queryArguments, isServiceReady]);

  let ret;
  if (isServiceReady && queryArgs != null) {
    ret = (
      <SmartQueryMap
        query={useGetPlacesJoinFilteredQuery}
        queryArguments={queryArgs}
        updateCount={updateCount}
        maxNumber={maxPlaceCount}
      />
    );
  } else {
    ret = <p>loading...</p>;
  }
  return ret;
}
