import { List, ListItem, ListItemText } from "@mui/material";
import { isObject } from "lodash";
import React, { useMemo } from "react";
import { isJSONString } from "../../utils/Utils";
import SmartAccordionLazy from "../accordion/SmartAccordionLazy";
import SmartAttribute from "./SmartAttribute";

type IProps = {
  dataObject: any;
  hide?: boolean; // not visible if true
  open?: boolean; // open means expanded
};

function GenericInfoComponent({
  dataObject,
  hide = false,
  open = true,
}: IProps): JSX.Element | null {
  const summaryText = useMemo(
    () => _createSummaryText(dataObject),
    [dataObject]
  );

  function createAccordionChilds() {
    const ui = _createUI(dataObject, hide);
    return (
      <List component="nav" dense={true} key="outer">
        {ui}
      </List>
    );
  }

  return (
    <SmartAccordionLazy
      childrenCallback={createAccordionChilds}
      header={summaryText}
      defaultExpanded={open}
    />
  );
}

//////////////////////  Helper Functions  //////////////////////

function _createUI(dataObject: any, hide: boolean | undefined) {
  if (hide === true) {
    if (localStorage.getItem("info") !== "true") {
      return null;
    }
  }
  let ret: any[] = [];
  if (dataObject) {
    const p = _processDataObject(dataObject, 0);
    ret = p.retList;
  }
  return ret;
}
function _createSummaryText(dataObject: any) {
  let summaryText = "Eigenschaften";
  if (dataObject) {
    const p = _processDataObject(dataObject, 0);
    summaryText = p.summaryText;
  }
  return summaryText;
}

function _processDataObject(dataObject: any, indent: number) {
  const retList = [];
  let summaryText = "";
  const keys = Object.keys(dataObject);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const value = dataObject[key];
    const isJson = isJSONString(value);
    if (typeof value === "boolean") {
      const valueText = Boolean(value).toString();
      const leftIndent = 4 * indent;
      retList.push(
        <ListItem key={key} sx={{ pl: leftIndent }}>
          {_createAttributeComponent(key, valueText)}
        </ListItem>
      );
    } else if (
      Array.isArray(value) &&
      value.every((v) => {
        return isObject(v);
      })
    ) {
      const tempObject = _fromEntries(value);
      const subRet = _processDataObject(tempObject, indent + 1);
      const subList = subRet.retList;
      const leftIndent = 4 * indent;
      retList.push(
        <ListItem key={key} sx={{ pl: leftIndent }}>
          <ListItemText primary={key + " (JSON)"} />
        </ListItem>
      );
      retList.push(
        <List component="nav" dense={true} key={key + "_sub"}>
          {subList}
        </List>
      );
    } else if (isJson) {
      const parsed = JSON.parse(value);
      const subRet = _processDataObject(parsed, indent + 1);
      const subList = subRet.retList;
      const leftIndent = 4 * indent;
      retList.push(
        <ListItem key={key} sx={{ pl: leftIndent }}>
          <ListItemText primary={key + " (JSON)"} />
        </ListItem>
      );
      retList.push(
        <List component="nav" dense={true} key={key + "_sub"}>
          {subList}
        </List>
      );
    } else if (value instanceof Object) {
      const subRet = _processDataObject(value, indent + 1);
      const subList = subRet.retList;
      const leftIndent = 4 * indent;
      retList.push(
        <ListItem key={key} sx={{ pl: leftIndent }}>
          <ListItemText primary={key + " (JSON)"} />
        </ListItem>
      );
      retList.push(
        <List component="nav" dense={true} key={key + "_sub"}>
          {subList}
        </List>
      );
    } else {
      const excludeArray = [
        "images",
        "bookable",
        "adac_prices",
        "marketing_data",
        "adac_attributes",
      ];
      if (key && !excludeArray.includes(key)) {
        const leftIndent = 4 * indent;
        retList.push(
          <ListItem key={key} sx={{ pl: leftIndent }}>
            {_createAttributeComponent(key, value)}
          </ListItem>
        );
      } else {
        console.log("key for else: " + key);
      }
    }
  }
  if (keys) {
    const len = keys.length;
    if (len === 0) {
      summaryText = "keine Eigenschaften vorhanden";
    } else if (len === 1) {
      summaryText = "eine Eigenschaft vorhanden";
    } else {
      summaryText = len + " Eigenschaften vorhanden";
    }
  }
  return { retList, summaryText };
}

function _fromEntries(value: any[]) {
  const ret = Object.entries(value);
  // const ret = Object.fromEntries(value);
  return ret;
}

function _createAttributeComponent(key: string, value: any) {
  // return <ObjectAttributeComponent attributeKey={key} dataObject={value} />
  return <SmartAttribute label={key} value={value} />;
}

export default GenericInfoComponent;
