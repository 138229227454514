import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { TextFieldElement } from "react-hook-form-mui";
import { makeZodI18nMap } from "zod-i18n-map";
import { z } from "../../i18n";
import { usePlacelistsService } from "../../services/PlacelistsService";
import useTranslationService from "../../services/TranslationService";
import { IPlacelist } from "../../stuff/AppModelTypes";
import { BetterDialog } from "../dialogs/BetterDialog";

interface IProps {
  open: boolean;
  title?: string;
  placelist: IPlacelist;
  onClose: () => void;
}
const schema = z.object({
  name: z.string().min(1),
});

type FormFields = z.infer<typeof schema>;

export default function RenamePlacelistDialog({
  open,
  title = "",
  placelist,
  onClose,
}: IProps) {
  const { t, tCapitalize } = useTranslationService();
  const inputRefForInitialFocus = useRef<HTMLInputElement>(null);
  z.setErrorMap(makeZodI18nMap({ t }));
  const { updatePlacelistName } = usePlacelistsService();
  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    handleSubmit,
    setError,
    formState: {
      // errors
      isSubmitting,
    },
    reset,
  } = useForm<FormFields>({
    defaultValues: {
      name: placelist.Name,
    },
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (open && inputRefForInitialFocus.current != null) {
        inputRefForInitialFocus.current.focus();
      }
    }, 0);
    return () => clearTimeout(timeout);
  }, [open]);

  const onSubmit: SubmitHandler<FormFields> = async (data) => {
    const newName = data.name.trim();
    if (newName === placelist.Name) {
      setError("name", {
        message: "Der Name wurde nicht geändert",
      });
      return false;
    }
    try {
      updatePlacelistName(placelist.Id, newName);
      enqueueSnackbar("renaming is done");
      onClose();
    } catch (error) {
      setError("root", {
        message: "Der Name wurde nicht geändert",
      });
    }
  };

  function handleDialogClose(event: any) {
    onClose();
    reset();
    return false;
  }

  return (
    <BetterDialog open={open}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle
          onClick={(e) => {
            console.dir(e);
            return false;
          }}
        >
          {title}
        </DialogTitle>
        <DialogContent>
          <TextFieldElement
            focused={true}
            autoFocus
            inputRef={inputRefForInitialFocus}
            name="name"
            control={control}
            label={tCapitalize("name")}
            required={true}
            variant="standard"
            fullWidth={true}
          />
          {/* {errors.root && <Alert severity="error">{errors.root.message}</Alert>} */}
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleDialogClose}>{t("cancel")}</Button> */}
          <Button onClick={handleDialogClose}>{t("cancel")} </Button>
          <Button
            disabled={isSubmitting}
            type="submit"
            onClick={(event) => {
              return false;
            }}
          >
            {isSubmitting ? t("loading") : t("ok")}
          </Button>
        </DialogActions>
      </form>
    </BetterDialog>
  );
}
