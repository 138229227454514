import React from "react";
import ISmartAction from "../../components/buttons/ISmartAction";
import { IPlacelist } from "../../stuff/AppModelTypes";
import { ArticleTwoTone } from "@mui/icons-material";

function createNavigateToPlacelistDetailsAction(
  placelist: IPlacelist,
  navigate: any,
  currentPathname?: string
): ISmartAction {
  let text = "Details anzeigen";
  const plId = placelist ? placelist.Id : null;
  function handleMouse() {
    if (navigate != null) {
      navigate("/placelist/" + plId);
    }
  }
  if (placelist) {
    let plName = plId;
    if (placelist.Name != null) {
      plName = "'" + placelist.Name + "'";
    }
    if (plName != null && plName.length > 0) {
      text = "Details zu Placelist " + plName + " anzeigen";
    }
  }
  return {
    text,
    shortText: "Details",
    onMouse: handleMouse,
    children: <ArticleTwoTone />,
    disabled: plId == null || navigate == null,
    invisible:
      currentPathname != null && currentPathname.includes("/placelist/" + plId),
  };
}

export default createNavigateToPlacelistDetailsAction;
