import React from "react";
import { AbstractPlacesSincePage } from "./AbstractPlacesSincePage";

export function NewPlacesSincePage() {
  return (
    <AbstractPlacesSincePage
      titleKey={"new-places-from-the-last-time"}
      queryKey={"fromCreatedAt"}
    />
  );
}
