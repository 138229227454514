import { Typography } from "@mui/material";
import React from "react";
import { useUserService } from "../services/UserService";
import PlacelistsForUserComponent from "./../components/placelists/PlacelistForUserComponent";
import { StyledContent } from "./../components/styles/styled.Content";
import { useTranslation } from "react-i18next";

function MyPlacelistsPage() {
  const { t } = useTranslation();
  const { userId, isServiceReady } = useUserService();
  if (!isServiceReady) {
    return <Typography>{t("loading")}</Typography>;
  }

  const placelists = userId && <PlacelistsForUserComponent userId={userId} />;

  return <StyledContent>{placelists}</StyledContent>;
}

export default MyPlacelistsPage;
