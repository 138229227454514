import { Slider } from "@mui/material";
import { MAX_STATISTICS_POINTS } from "../api/PlacesApi";
import { useStatistics } from "../api/hooks";
import StatisticsComponent from "../components/StatisticsComponent";
import { StyledContent } from "../components/styles/styled.Content";
import useLocalStorage from "../services/LocalStorageService";

const SET_HEARTBEAT = [
  {
    name: "placesDiffRel",
    color: "#82ca9d",
  },
  {
    name: "scrapeLinksDiffRel",
    color: "#8884d8",
  },
];
const SET_PLACES = [
  {
    name: "places",
    color: "#82ca9d",
  },
  {
    name: "placesWithoutLand",
    color: "#8884d8",
  },
  {
    name: "placesWithoutDomains",
    color: "#CD5C5C",
  },
];
const SET_SCRAPE_LINKS_STATUS = [
  {
    name: "scrapeLinks",
    color: "#82ca9d",
  },
  {
    name: "scrapeLinksWithStatus",
    color: "#8884d8",
  },
  {
    name: "scrapeLinksWithoutStatus",
    color: "#CD5C5C",
  },
];

export function StatisticsPage() {
  // const [size, setSize] = useState(100);
  const [size, setSize] = useLocalStorage("StatisticsPage_size", 100);
  const [statistics] = useStatistics();

  function valuetext(value) {
    return `${value} Datenpunkte`;
  }
  const handleSliderChange = (event, newValue) => {
    setSize(newValue);
  };

  return (
    <StyledContent>
      <Slider
        aria-label="Always visible"
        defaultValue={size}
        getAriaValueText={valuetext}
        step={10}
        // marks={MARKS}
        valueLabelDisplay="on"
        onChange={handleSliderChange}
        min={10}
        max={MAX_STATISTICS_POINTS}
      />
      <StatisticsComponent
        key={"SET_SCRAPE_LINKS_STATUS"}
        size={size}
        statistics={statistics}
        set={SET_SCRAPE_LINKS_STATUS}
      />
      <StatisticsComponent
        key={"scrapeLinksWithStatus"}
        size={size}
        statistics={statistics}
        set={[
          {
            name: "scrapeLinksWithStatus",
            color: "#CD5C5C",
          },
        ]}
      />
      <StatisticsComponent
        key={"SET_PLACES"}
        size={size}
        statistics={statistics}
        set={SET_PLACES}
      />
      <StatisticsComponent
        key={"SET_HEARTBEAT"}
        size={size}
        statistics={statistics}
        set={SET_HEARTBEAT}
      />
    </StyledContent>
  );
}
