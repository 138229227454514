import React from "react";
import { ShowersChip } from "./ShowersChip";
import { IPlace } from "../../../stuff/AppModelTypes";
import { ToiletsChip } from "./ToiletsChip";
import { SwimmingChip } from "./SwimmingChip";
import { PetsAllowedChip } from "./PetsAllowedChip";
import {
  BLACK_WATER_DISPOSAL,
  FRESH_WATER_SUPPLY,
  GARBAGE_DISPOSAL,
  GRAY_WATER_DISPOSAL,
  WASHING_MACHINE,
  PETS_ALLOWED,
  POWER_CONNECTION,
  SHOWERS,
  SWIMMING_POOL,
  TOILETS,
  TUMBLE_DRYER,
  WASTE_WATER_CONNECTION,
  compareFeatures,
  FRESH_WATER_CONNECTION,
  WASHBOWL,
  WINTER_CARAVANNING,
  WIFI,
  TV_CONNECTION,
  GAS_BOTTLE_SERVICE,
  WASHING_MOTORHOME,
  PLAYGROUND,
  SHADING,
  GUARDING,
  BAKED_GOODS,
} from "../../../api/FeaturesConstants";
import { FreshWaterSupplyChip } from "./FreshWaterSupplyChip";
import { GrayWaterDisposalChip } from "./GrayWaterDisposalChip";
import { WashingMachineChip } from "./WashingMachineChip";
import { BlackWaterDisposalChip } from "./BlackWaterDisposalChip";
import { GarbageDisposalChip } from "./GarbageDisposalChip";
import { TumbleDryerChip } from "./TumbleDryerChip";
import { PowerConnectionChip } from "./PowerConnectionChip";
import { WasteWaterConnectionChip } from "./WasteWaterConnectionChip";
import { FreshWaterConnectionChip } from "./FreshWaterConnectionChip";
import { GenericFeatureChip } from "./GenericFeatureChip";

type Props = {
  place: IPlace;
};
export function FeaturesInfo({ place }: Props) {
  let ret = null;

  if (place != null) {
    const faString = place.FeaturesAvailable;
    if (faString != null) {
      const featuresAvailable = JSON.parse(faString);
      const chipObjects: any[] = [];
      chipObjects.push(
        _createChipObject(featuresAvailable, FRESH_WATER_SUPPLY, (f: any) => (
          <FreshWaterSupplyChip key={"FreshWaterSupplyChip"} feature={f} />
        ))
      );
      chipObjects.push(
        _createChipObject(featuresAvailable, GRAY_WATER_DISPOSAL, (f: any) => (
          <GrayWaterDisposalChip key={"GrayWaterDisposalChip"} feature={f} />
        ))
      );
      chipObjects.push(
        _createChipObject(featuresAvailable, BLACK_WATER_DISPOSAL, (f: any) => (
          <BlackWaterDisposalChip key={"BlackWaterDisposalChip"} feature={f} />
        ))
      );
      chipObjects.push(
        _createChipObject(
          featuresAvailable,
          FRESH_WATER_CONNECTION,
          (f: any) => (
            <FreshWaterConnectionChip
              key={"FreshWaterConnectionChip"}
              feature={f}
            />
          )
        )
      );
      chipObjects.push(
        _createChipObject(
          featuresAvailable,
          WASTE_WATER_CONNECTION,
          (f: any) => (
            <WasteWaterConnectionChip
              key={"WasteWaterConnectionChip"}
              feature={f}
            />
          )
        )
      );
      chipObjects.push(
        _createChipObject(featuresAvailable, POWER_CONNECTION, (f: any) => (
          <PowerConnectionChip key={"PowerConnectionChip"} feature={f} />
        ))
      );
      chipObjects.push(
        _createChipObject(featuresAvailable, GARBAGE_DISPOSAL, (f: any) => (
          <GarbageDisposalChip key={"GarbageDisposalChip"} feature={f} />
        ))
      );
      chipObjects.push(
        _createChipObject(featuresAvailable, SHOWERS, (f: any) => (
          <ShowersChip key={"ShowersChip"} feature={f} />
        ))
      );
      chipObjects.push(
        _createChipObject(featuresAvailable, TOILETS, (f: any) => (
          <ToiletsChip key={"ToiletsChip"} feature={f} />
        ))
      );
      chipObjects.push(
        _createChipObject(featuresAvailable, PETS_ALLOWED, (f: any) => (
          <PetsAllowedChip key={"PetsAllowedChip"} feature={f} />
        ))
      );
      chipObjects.push(
        _createChipObject(featuresAvailable, WASHING_MACHINE, (f: any) => (
          <WashingMachineChip key={"WashingMachineChip"} feature={f} />
        ))
      );
      chipObjects.push(
        _createChipObject(featuresAvailable, TUMBLE_DRYER, (f: any) => (
          <TumbleDryerChip key={"TumbleDryerChip"} feature={f} />
        ))
      );
      chipObjects.push(_createChipObject(featuresAvailable, WASHBOWL));
      chipObjects.push(
        _createChipObject(featuresAvailable, WINTER_CARAVANNING)
      );
      chipObjects.push(_createChipObject(featuresAvailable, WIFI));
      chipObjects.push(_createChipObject(featuresAvailable, TV_CONNECTION));
      chipObjects.push(_createChipObject(featuresAvailable, BAKED_GOODS));
      chipObjects.push(
        _createChipObject(featuresAvailable, GAS_BOTTLE_SERVICE)
      );
      chipObjects.push(
        _createChipObject(featuresAvailable, SWIMMING_POOL, (f: any) => (
          <SwimmingChip key={"SwimmingChip"} feature={f} />
        ))
      );
      chipObjects.push(_createChipObject(featuresAvailable, WASHING_MOTORHOME));
      chipObjects.push(_createChipObject(featuresAvailable, PLAYGROUND));
      chipObjects.push(_createChipObject(featuresAvailable, SHADING));
      chipObjects.push(_createChipObject(featuresAvailable, GUARDING));
      chipObjects.sort((a, b) => compareFeatures(a, b));
      ret = (
        <>
          {chipObjects.map((co) => {
            return co.chip;
          })}
        </>
      );
    }
  }
  return ret;
}

function _findFeature(features: any, key: string) {
  for (let index = 0; index < features.length; index++) {
    const f = features[index];
    if (key === f.key) {
      return f;
    }
  }
  return null;
}

function _createChipObject(
  features: any[],
  featureConst: any,
  creatorCallback?: any
) {
  let c = null;
  const featureKey = featureConst.key;
  const f = _findFeature(features, featureKey);
  if (f != null) {
    if (creatorCallback != null) {
      c = creatorCallback(f);
    } else {
      c = <GenericFeatureChip key={featureKey} featureConst={featureConst} />;
    }
  }
  return {
    chip: c,
    weight: featureConst.weight,
  };
}
