import React from "react";
import useTranslationService from "../../../services/TranslationService";
import { SmartChip } from "../../chip/SmartChip";
import { FeatureConst } from "../../../api/FeaturesConstants";

export type FeatureChipSubtypeProps = {
  feature?: any;
};

type Props = FeatureChipSubtypeProps & {
  featureConst: FeatureConst;
};

export function FeatureChip({ feature, featureConst }: Props) {
  const { tCapitalize } = useTranslationService();
  const text = tCapitalize(featureConst.translationKey);
  let tooltip = text;
  if (feature != null) {
    const fText = feature.text;
    if (fText != null && fText.length > 0) {
      tooltip = tooltip + " - " + fText;
    }
  }
  let icon = null;
  if (featureConst.icon != null) {
    icon = React.createElement(featureConst.icon);
  }
  return (
    <SmartChip
      key={featureConst.key}
      color="success"
      icon={icon}
      text={text}
      tooltip={tooltip}
    />
  );
}
