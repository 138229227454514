import React from "react";
import { AbstractPlacesSincePage } from "./AbstractPlacesSincePage";

export function UpdatedPlacesSincePage() {
  return (
    <AbstractPlacesSincePage
      titleKey={"updated-places-from-the-last-time"}
      queryKey={"fromUpdatedAt"}
    />
  );
}
