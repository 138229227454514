import { ButtonGroup, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { isNullOrUndefined } from "../../utils/Utils";
import PlacelistsResultList from "./PlacelistsResultList";

export function PlacelistsListWithFilter() {
  const { t } = useTranslation();
  const [searchString, setSearchString] = useState("");
  const [queryProps, setQueryProps] = useState({});

  useEffect(() => {
    const props = {
      searchString: searchString,
      showOnlyPublicPlacelists: true,
    };
    setQueryProps(props);
  }, [searchString]);

  let list = <Typography>{t("loading")}</Typography>;
  if (!isNullOrUndefined(queryProps) && Object.keys(queryProps).length > 0) {
    list = <PlacelistsResultList queryArguments={queryProps} />;
  }

  return (
    <>
      <ButtonGroup>
        <TextField
          id="outlined-basic"
          label={t("search-in-name")}
          value={searchString}
          type="search"
          onChange={(e) => setSearchString(e.target.value)}
        />
      </ButtonGroup>
      {list}
    </>
  );
}
