import {
  useCreatePlaceAttachmentMutation,
  useGetPlaceAttachmentsForPlaceQuery,
} from "../../api/PlacesApiSlice";
import usePlaceService from "../../services/PlaceService";
import { IPlace, IPlaceAttachment } from "../../stuff/AppModelTypes";

export function usePlaceAttachmentsServiceFromId(placeId?: string) {
  if (placeId == null) {
    placeId = "";
  }
  const { placeObject } = usePlaceService(placeId);
  return usePlaceAttachmentsService(
    placeObject != null ? placeObject : undefined
  );
}
export function usePlaceAttachmentsService(placeObject?: IPlace) {
  const placeId = placeObject != null ? placeObject.Id : null;
  let placeAttachments =
    placeObject != null && placeObject.PlaceAttachments != null
      ? placeObject.PlaceAttachments
      : [];
  const skip = placeAttachments != null;
  const { data: placeAttachmentsOfPlaceQueryResult } =
    useGetPlaceAttachmentsForPlaceQuery(placeId, { skip });
  const [_createPlaceAttachment] = useCreatePlaceAttachmentMutation();

  function createPlaceAttachment(url: string) {
    if (url == null) {
      throw new Error("no url provided");
    }
    const data = { url: url };
    const newEntry = { PlaceId: placeId, Data: JSON.stringify(data) };
    _createPlaceAttachment(newEntry);
  }

  const ret = {
    // placeAttachments: [] as IPlaceAttachment[],
    placeAttachments,
    createPlaceAttachment,
  };

  if (placeAttachments == null && placeAttachmentsOfPlaceQueryResult != null) {
    const records = placeAttachmentsOfPlaceQueryResult.records;
    if (records != null && records.length > 0) {
      ret.placeAttachments = _getPlaceAttachmentsFromRecords(records);
    }
  }

  return ret;
}

function _getPlaceAttachmentsFromRecords(records: any[]): IPlaceAttachment[] {
  const ret = [];
  for (let index = 0; index < records.length; index++) {
    const r = records[index];
    const pa = _getPlaceAttachmentFromRecord(r);
    ret.push(pa);
  }
  return ret;
}
function _getPlaceAttachmentFromRecord(record: any): IPlaceAttachment {
  let ret: IPlaceAttachment = {
    Id: "" + record.Id,
    PlaceId: record.PlaceId,
    Data: JSON.parse(record.Data),
  };
  return ret;
}
