import React from "react";
import SmartButton from "../buttons/SmartButton";
import { IDialogProps } from "./IDialogProps";

interface IProps {
  text: string;
  shortText: string;
  showLabel: boolean;
  children: any;
  dialogProps: IDialogProps;
}

export function SmartDialogButton(props: IProps): JSX.Element | null {
  return (
    <SmartButton
      text={props.text}
      shortText={props.shortText}
      showLabel={props.showLabel}
      children={props.children}
      dialogProps={props.dialogProps}
    />
  );
}
