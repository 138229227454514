import { LatLng, LatLngBounds } from "leaflet";

export type ICoord = {
  lat: number;
  lon: number;
};
export type IBound = {
  east: number;
  north: number;
  west: number;
  south: number;
};

export function arrayToBounds(array: number[]): IBound {
  return { south: array[0], west: array[1], north: array[2], east: array[3] };
}

export function toLatLng(location: any): { lng: number; lat: number } | null {
  if (location != null) {
    const longitude = location.longitude || location.Longitude || location.lng;
    const latitude = location.latitude || location.Latitude || location.lat;

    if (longitude != null && latitude != null) {
      return { lng: longitude, lat: latitude };
    }
  }

  return null;
}

export function boundToLatLngBoundsExpression(bound: IBound) {
  const southwest = new LatLng(bound.west, bound.south);
  const northeast = new LatLng(bound.east, bound.north);
  return new LatLngBounds(southwest, northeast);
}

export function calculateBoundsAroundPlaces(places: any[]) {
  const coords = [] as ICoord[];
  for (let index = 0; index < places.length; index++) {
    const p = places[index];
    const lat = p.Latitude;
    const lon = p.Longitude;
    if (lat != null && lon != null) {
      const newCoord: ICoord = { lat, lon };
      coords.push(newCoord);
    }
  }
  return calculateBoundsAroundCoords(coords);
}

export function calculateBoundsAroundCoords(coords: ICoord[]) {
  let west = undefined;
  let east = undefined;
  let south = undefined;
  let north = undefined;
  for (let index = 0; index < coords.length; index++) {
    const c = coords[index];
    {
      const lon = c.lon;
      if (west == null) {
        west = lon;
      } else {
        west = Math.min(west, lon);
      }
      if (east == null) {
        east = lon;
      } else {
        east = Math.max(east, lon);
      }
    }
    {
      const lat = c.lat;
      if (south == null) {
        south = lat;
      } else {
        south = Math.min(south, lat);
      }
      if (north == null) {
        north = lat;
      } else {
        north = Math.max(north, lat);
      }
    }
  }
  function _init(a: any) {
    return a != null ? a : 0;
  }
  west = _init(west);
  east = _init(east);
  south = _init(south);
  north = _init(north);
  return { west, east, north, south };
}

export function spreadBoundsToMinimum(
  bounds: IBound,
  min: number,
  extraPercentage?: {
    west?: number;
    east?: number;
    north?: number;
    south?: number;
  }
): IBound {
  let ret = bounds;
  let deltaX = Math.abs(bounds.west - bounds.east);
  if (deltaX < min) {
    deltaX = min;
    const middle = (bounds.west + bounds.east) / 2;
    ret.west = middle - min / 2;
    ret.east = middle + min / 2;
  }
  let deltaY = Math.abs(bounds.north - bounds.south);
  if (deltaY < min) {
    deltaY = min;
    const middle = (bounds.north + bounds.south) / 2;
    ret.south = middle - min / 2;
    ret.north = middle + min / 2;
  }
  if (extraPercentage != null) {
    if (extraPercentage.west != null && extraPercentage.west !== 0) {
      const absExtra = (deltaX * extraPercentage.west) / 100;
      ret.west = ret.west - absExtra;
    }
    if (extraPercentage.east != null && extraPercentage.east !== 0) {
      const absExtra = (deltaX * extraPercentage.east) / 100;
      ret.east = ret.east + absExtra;
    }
    if (extraPercentage.south != null && extraPercentage.south !== 0) {
      const absExtra = (deltaX * extraPercentage.south) / 100;
      ret.south = ret.south - absExtra;
    }
    if (extraPercentage.north != null && extraPercentage.north !== 0) {
      const absExtra = (deltaX * extraPercentage.north) / 100;
      ret.north = ret.north + absExtra;
    }
  }
  return ret;
}

export function uniteBounds(a: IBound, b: IBound) {
  const east = Math.max(a.east, b.east);
  const north = Math.max(a.north, b.north);
  const west = Math.min(a.west, b.west);
  const south = Math.min(a.south, b.south);
  return { east, north, west, south };
}

export function makeSmartDefaults(
  width: string | undefined,
  height: string | undefined
): { width: string; height: string } {
  const DEFAULT_SIZE = "300px";
  let ret = { width: DEFAULT_SIZE, height: DEFAULT_SIZE };
  if (width == null && height == null) {
    //ret = { width: DEFAULT_SIZE, height: DEFAULT_SIZE };
  } else {
    if (width != null) {
      ret.width = width;
      if (height == null) {
        ret.height = width;
      }
    }
    if (height != null) {
      ret.height = height;
      if (width == null) {
        ret.width = height;
      }
    }
  }
  return ret;
}
