import React from "react";
import { IPlace } from "../../stuff/AppModelTypes";
import GenericList from "../place/parts/GenericList";
import { createPlaceComponent } from "./PlaceRenderUtils";

type IProps = {
  places: IPlace[];
  parent?: any;
  fromLatiude?: number;
  fromLongitude?: number;
  infoVisibility?: "expanded" | "collapsed" | "hidden";
};

function PlacesList(props: IProps) {
  return (
    <GenericList
      elements={props.places}
      renderProps={props}
      renderComponent={createPlaceComponent}
    />
  );
}

export default PlacesList;
