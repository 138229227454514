import { useEffect, useState } from "react";
import { getStatistics } from "./PlacesApi";

export function useStatistics() {
  const [statistics, setStatistics] = useState([]);

  useEffect(() => {
    const fetchStatistics = async () => {
      // if (statistics && statistics.length > 0) {
      //   return;
      // }
      const res = await getStatistics();
      function mapIt(r) {
        const data = JSON.parse(r.DataPlus);
        const ret = {
          ...data,
          date: r.Date,
          dateToNumber: new Date(r.Date).getTime(),
        };
        return ret;
      }
      if (res && res.length > 0) {
        const newData = res.map((r) => mapIt(r));
        setStatistics(newData);
      }
    };

    fetchStatistics();
  }, []);

  return [statistics];
}
