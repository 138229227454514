import {
  AcUnitTwoTone,
  BakeryDiningTwoTone,
  CheckroomTwoTone,
  ChildCareTwoTone,
  ConnectedTvTwoTone,
  DeleteTwoTone,
  ElectricalServicesTwoTone,
  GetAppTwoTone,
  GppGoodTwoTone,
  LinkTwoTone,
  LocalCarWashTwoTone,
  LocalLaundryServiceTwoTone,
  PetsTwoTone,
  PoolTwoTone,
  PropaneTankTwoTone,
  ScienceTwoTone,
  ShowerTwoTone,
  SoapTwoTone,
  WaterDropTwoTone,
  WbShadeTwoTone,
  WcTwoTone,
  WifiTwoTone,
} from "@mui/icons-material";

export type FeatureConst = {
  key: string;
  translationKey: string;
  icon?: any;
  weight?: number;
};

export const FRESH_WATER_SUPPLY = {
  key: "FreshWaterSupply",
  translationKey: "fresh-water-supply",
  icon: WaterDropTwoTone,
  weight: 101,
};
export const GRAY_WATER_DISPOSAL = {
  key: "GrayWaterDisposal",
  translationKey: "gray-water-disposal",
  icon: GetAppTwoTone,
  weight: 102,
};
export const BLACK_WATER_DISPOSAL = {
  key: "BlackWaterDisposal",
  translationKey: "black-water-disposal",
  icon: ScienceTwoTone,
  weight: 103,
};
export const FRESH_WATER_CONNECTION = {
  key: "FreshWaterConnection",
  translationKey: "fresh-water-connection",
  icon: LinkTwoTone,
  weight: 111,
};
export const WASTE_WATER_CONNECTION = {
  key: "WasteWaterConnection",
  translationKey: "waste-water-connection",
  icon: LinkTwoTone,
  weight: 112,
};
export const POWER_CONNECTION = {
  key: "PowerConnection",
  translationKey: "power-connection",
  icon: ElectricalServicesTwoTone,
  weight: 201,
};
export const GARBAGE_DISPOSAL = {
  key: "GarbageDisposal",
  translationKey: "garbage-disposal",
  icon: DeleteTwoTone,
  weight: 301,
};
export const SHOWERS = {
  key: "Showers",
  translationKey: "showers",
  icon: ShowerTwoTone,
  weight: 401,
};
export const TOILETS = {
  key: "Toilets",
  translationKey: "toilets",
  icon: WcTwoTone,
  weight: 402,
};
export const PETS_ALLOWED = {
  key: "PetsAllowed",
  translationKey: "pets-allowed",
  icon: PetsTwoTone,
  weight: 500,
};
export const WASHING_MACHINE = {
  key: "WashingMachine",
  translationKey: "washing-machine",
  icon: LocalLaundryServiceTwoTone,
  weight: 701,
};
export const TUMBLE_DRYER = {
  // Wäschetrockner
  key: "TumbleDryer",
  translationKey: "tumble-dryer",
  icon: CheckroomTwoTone,
  weight: 702,
};
export const WASHBOWL = {
  key: "Washbowl",
  translationKey: "washbowl",
  icon: SoapTwoTone,
  weight: 799,
};
export const WINTER_CARAVANNING = {
  key: "WinterCaravanning",
  translationKey: "winter-caravaning",
  icon: AcUnitTwoTone,
  weight: 999,
};
export const WIFI = {
  key: "Wifi",
  translationKey: "wifi",
  icon: WifiTwoTone,
  weight: 999,
};
export const TV_CONNECTION = {
  key: "TvConnection",
  translationKey: "tv-connection",
  icon: ConnectedTvTwoTone,
  weight: 999,
};
export const BAKED_GOODS = {
  key: "BakedGoods",
  translationKey: "baked-goods",
  icon: BakeryDiningTwoTone,
  weight: 999,
};
export const GAS_BOTTLE_SERVICE = {
  key: "GasBottleService",
  translationKey: "gas-bottle-service",
  icon: PropaneTankTwoTone,
  weight: 999,
};
export const SWIMMING_POOL = {
  key: "SwimmingPool",
  translationKey: "swimming-pool",
  icon: PoolTwoTone,
  weight: 999,
};
export const WASHING_MOTORHOME = {
  key: "WashingMotorhome",
  translationKey: "washing-motorhome",
  icon: LocalCarWashTwoTone,
  weight: 999,
};
export const PLAYGROUND = {
  key: "Playground",
  translationKey: "playground",
  icon: ChildCareTwoTone,
  weight: 999,
};
export const SHADING = {
  key: "Shading",
  translationKey: "shading",
  icon: WbShadeTwoTone,
  weight: 999,
};
export const GUARDING = {
  key: "Guarding",
  translationKey: "guarding",
  icon: GppGoodTwoTone,
  weight: 999,
};

export function compareFeatures(a: any, b: any) {
  let ret = 0;
  if (a == null || b == null || a.weight == null || b.weight == null) {
    ret = 0;
  } else {
    ret = a.weight - b.weight;
  }
  return ret;
}

export const FEATURES_ALL: FeatureConst[] = [
  FRESH_WATER_SUPPLY,
  GRAY_WATER_DISPOSAL,
  BLACK_WATER_DISPOSAL,
  POWER_CONNECTION,
  FRESH_WATER_CONNECTION,
  WASTE_WATER_CONNECTION,
  GARBAGE_DISPOSAL,
  SHOWERS,
  WIFI,
  TOILETS,
  WINTER_CARAVANNING,
  PETS_ALLOWED,
  TV_CONNECTION,
  BAKED_GOODS,
  GAS_BOTTLE_SERVICE,
  SWIMMING_POOL,
  WASHBOWL,
  WASHING_MACHINE,
  TUMBLE_DRYER,
  WASHING_MOTORHOME,
  PLAYGROUND,
  SHADING,
  GUARDING,
];
