import React from "react";
import { useGetPlacesJoinFilteredQuery } from "../api/PlacesApiSlice";
import { SmartQueryList } from "./../components/lists/SmartQueryList";
import PlaceSmall from "./../components/place/PlaceSmall";

export function TestPage2() {
  return (
    <SmartQueryList
      query={useGetPlacesJoinFilteredQuery}
      renderObject={renderPlace}
    />
  );
}

function renderPlace(object) {
  return <PlaceSmall placeObject={object} />;
}
