import { Link, TypographyOwnProps } from "@mui/material";
import React from "react";

interface IProps {
  text: string;
  href: string;
  variant?: TypographyOwnProps["variant"];
}

export function TitleLink({ text, href, variant = "h4" }: IProps) {
  return (
    <Link variant={variant} href={href} color="primary" underline="hover">
      {text}
    </Link>
  );
}
