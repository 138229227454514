import { Marker, Popup, Tooltip } from "react-leaflet";
import styled from "styled-components";
import {
  isArray,
  isNullOrUndefined,
  isString,
  numberToMoney,
} from "../../utils/Utils";
import PlaceSmallSimple from "./../place/PlaceSmallSimple";
import { createIcon } from "./LeafletUtils";

const SHOW_POPUP = true;

const StyledMarker = styled(Marker)`
  opacity: MARKER_OPACITY_FULL;
`;

export default function MapMarker(props) {
  const activePlaceId = props.activePlaceId;

  const p = props.data;
  const hl = activePlaceId === p.Id;

  const icon = _createIcon(p, hl);

  const oldEventHandlers = props.eventHandlers;
  const newEventHandlers = {
    click: (e) => {
      oldEventHandlers.click(e);
    },
  };
  const newProps = { ...props, icon, eventHandlers: newEventHandlers };

  const popup = SHOW_POPUP ? (
    <Popup
      // maxHeight={300}
      autoPan={false}
      keepInView={false}
      autoClose={false}
      closeButton={false}
    >
      {/* <PlaceSmallTooltip key={p.Id} placeObject={p} /> */}
      <PlaceSmallSimple placeObject={p} infoVisibility="hidden" />
      {/* createTooltip(p) */}
    </Popup>
  ) : null;
  return (
    <StyledMarker {...newProps}>
      <Tooltip direction="bottom">{_createTooltip(p)}</Tooltip>
      {popup}
    </StyledMarker>
  );
}

///////////////////   Helpers   ////////////////////////

function _createIcon(place, highlight) {
  const preis = place.Preis;
  let imageName = "";
  if (preis === undefined || preis === null) {
    imageName = "gray";
  } else if (preis > 30) {
    imageName = "pink";
  } else if (preis > 20) {
    imageName = "red";
  } else if (preis > 10) {
    imageName = "orange";
  } else if (preis > 0) {
    imageName = "yellow";
  } else if (preis === 0) {
    imageName = "green";
  }
  const iconUrl = "/images/pins/" + imageName + ".svg";
  const HIGHLIGHT_FACTOR = 1.7;
  const ret = createIcon(iconUrl, highlight, HIGHLIGHT_FACTOR);
  return ret;
}

function _createTooltip(place) {
  let ret;
  const preisText = isNullOrUndefined(place.Preis)
    ? null
    : numberToMoney(place.Preis);
  let domainsText = null;
  if (!isNullOrUndefined(place.Domains)) {
    if (isString(place.Domains) && place.Domains.length > 0) {
      domainsText = place.Domains;
    } else if (isArray(place.Domains) && place.Domains.length > 0) {
      domainsText = place.Domains.join(", ");
    }
  }
  const additionalInfoList = [];
  if (preisText) {
    additionalInfoList.push(preisText);
  }
  if (domainsText) {
    additionalInfoList.push(domainsText);
  }
  if (additionalInfoList.length < 1) {
    ret = place.Name;
  } else {
    ret = place.Name + " (" + additionalInfoList.join(", ") + ")";
  }
  return ret;
}
