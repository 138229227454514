import { AttachFileTwoTone } from "@mui/icons-material";
import React from "react";
import { IPlaceAttachment } from "../../stuff/AppModelTypes";
import { SmartChip } from "../chip/SmartChip";
import { stripDomainFromUrl } from "../../utils/Utils";

type Props = {
  placeAttachment: IPlaceAttachment;
};

export function PlaceAttachmentChip({ placeAttachment }: Props) {
  const data = JSON.parse(placeAttachment.Data);
  const url = data.url;
  return (
    <SmartChip
      icon={<AttachFileTwoTone />}
      text={stripDomainFromUrl(url)}
      href={url != null ? url : undefined}
      target="_blank"
      tooltip={url}
    />
  );
}
