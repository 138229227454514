import { Stack } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetPlacesFromPlacelist } from "../../services/PlacelistService";
import { IPlacelist } from "../../stuff/AppModelTypes";
import { PlacelistInfo } from "../info/PlacelistInfo";
import { CountriesMapForPlaces } from "../maps/CountriesMapForPlaces";
import SmartMap from "../maps/SmartMap";
import { MarkerVisualizations } from "../maps/SvgUtils";
import PlacesList from "../places/PlacesList";
import SmartTabs, { TabConf } from "../tabs/SmartTabs";
import { TitleContext } from "./../../TitleContext";
import PlacelistUsersComponent from "./PlacelistUsersComponent";
import { useUserService } from "../../services/UserService";
import GenericInfoComponent from "../others/GenericInfoComponent";

interface IProps {
  placelist: IPlacelist;
}

export default function PlacelistLarge({ placelist }: IProps) {
  const { t } = useTranslation();
  const { isSuperuser } = useUserService();
  const [title, setTitle] = useState<String | undefined>(undefined);
  const titleContext = useContext(TitleContext);
  const places = useGetPlacesFromPlacelist(placelist);
  const placeIds: string[] = [];
  if (places != null) {
    // subheaderText = "Placelist mit " + places.length + " Plätzen";
    for (let index = 0; index < places.length; index++) {
      const p = places[index];
      const id = p.Id;
      placeIds.push(id);
    }
  }
  // const { placeObjects } = usePlacesService(placeIds);

  function createPlacesList() {
    let ret: React.ReactElement | null = null;
    if (places) {
      ret = (
        <PlacesList
          key="place_list"
          places={places}
          parent={placelist}
          infoVisibility="collapsed"
        />
      );
    }
    return ret;
  }
  function createPlacesHeaderText() {
    let headerText = t("places");
    if (places) {
      const placeCount = places.length;
      if (placeCount === 0) {
        headerText = t("no-places");
      } else if (placeCount === 1) {
        headerText = t("one-place");
      } else if (placeCount > 1) {
        headerText = placeCount + " " + t("places");
      }
    }
    return headerText;
  }

  useEffect(() => {
    if (placelist != null) {
      if (placelist.Name != null) {
        setTitle(placelist.Name);
      }
    }
  }, [placelist]);

  useEffect(() => {
    if (title) {
      if (titleContext) {
        titleContext.setTitle(title);
      }
      // window.document.title = title;
    }
  }, [title, titleContext]);

  // const actions = usePlacelistActions(undefined, placelist).onePlacelistActions;
  // const actionbar = (
  //   <SmartActionBar actions={actions} accordionOpen={true} showLabels={true} />
  // );

  const tabs: TabConf[] = [
    {
      label: createPlacesHeaderText(),
      createUI: () => {
        return createPlacesList();
      },
    },
    {
      label: "Map",
      createUI: () => {
        return <SmartMap places={places} />;
      },
    },
  ];
  if (isSuperuser) {
    tabs.push({
      label: "Users",
      createUI: () => {
        return <PlacelistUsersComponent key="pluc" placelist={placelist} />;
      },
    });
  }
  if (isSuperuser) {
    tabs.push({
      label: "SU",
      createUI: () => {
        return <GenericInfoComponent dataObject={placelist} />;
        // <PlacelistUsersComponent key="pluc" placelist={placelist} />;
      },
    });
  }

  const ret = (
    <Stack key={"Stack_" + placelist.Id} spacing={1}>
      <Stack
        key="maps_and_info"
        direction="row"
        spacing={{ xs: 1, sm: 2 }}
        flexWrap="wrap"
        useFlexGap
        flexBasis="0"
      >
        <Stack
          key="stack_for_maps"
          direction="row"
          spacing={{ xs: 1, sm: 2 }}
          flexWrap="wrap"
          useFlexGap
        >
          <CountriesMapForPlaces
            width="300px"
            height="300px"
            places={places}
            markerVisualization={MarkerVisualizations.dot}
            disableMarkerClustering={true}
          />
        </Stack>
        {/* {actionbar} */}
        <PlacelistInfo placelist={placelist} />
      </Stack>
      <SmartTabs tabs={tabs} />
    </Stack>
  );
  return ret;
}
