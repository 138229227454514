import { NoteAddTwoTone } from "@mui/icons-material";
import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import ISmartAction from "../../components/buttons/ISmartAction";
import { IForm } from "../../components/forms/IForm";

function CreatePlacelistDialogContent(props: IForm): React.JSX.Element {
  const [nameText, setNameText] = useState<string>(props.data.name);
  useEffect(() => {
    if (props.onDataChange != null) {
      const data = { name: nameText };
      props.onDataChange(data);
    }
    if (props.onError != null) {
      if (nameText == null || nameText.length < 1) {
        props.onError("Bitte einen Namen für die Placelist eingeben");
      } else {
        props.onError(null);
      }
    }
  }, [nameText, props]);

  const content = (
    <TextField
      label="Name"
      defaultValue={nameText}
      margin="dense"
      fullWidth
      onChange={(e) => {
        setNameText(e.target.value);
      }}
    />
  );
  return content;
}

function createCreatePlacelistAction(createPlacelist: {
  (name: string): void;
  (arg0: string): void;
}): ISmartAction {
  function handleDialogOk(data: any) {
    const name: string = data.name;
    createPlacelist(name);
  }
  function _contentCreator(props: IForm) {
    const content = (
      <CreatePlacelistDialogContent
        data={{ name: "" }}
        onDataChange={props.onDataChange}
        onError={props.onError}
      />
    );
    return { content };
  }
  const ret: ISmartAction = {
    text: "Placelist anlegen",
    shortText: "Neu...",
    children: <NoteAddTwoTone />,
    dialogProps: {
      title: "Neue Placelist",
      onCreateContent: _contentCreator,
      onOk: handleDialogOk,
    },
  };
  return ret;
}

export default createCreatePlacelistAction;
