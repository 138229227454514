import "leaflet/dist/leaflet.css";
import React, { useEffect, useState } from "react";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import styled from "styled-components";
import { FLY_TO_NO_ANIMATION_SETTINGS } from "../../stuff/AppConstants";
import { createCrossIcon } from "./LeafletUtils";

const StyledMapContainer = styled(MapContainer)<{
  width: string;
  height: string;
}>`
  display: flex;
  flex-direction: column;
  width: ${(props: any) => {
    return props.width ? props.width : "400px";
  }};
  height: ${(props: any) => {
    return props.height ? props.height : "300px";
  }};
  flex-grow: 1;
  flex-shrink: 1;
`;

interface IProps {
  latitude: number;
  longitude: number;
  zoom: number;
  width: string;
  height: string;
  showCenterMarker?: boolean;
  showDebug?: boolean;
}

function LeafletImage({
  latitude,
  longitude,
  zoom,
  width,
  height,
  showCenterMarker,
  showDebug,
}: IProps): JSX.Element | null {
  if (showCenterMarker == null) {
    showCenterMarker = false;
  }
  const [map, setMap] = useState<any | null>(null);
  const newTL = (
    <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
  );

  let mc = null;
  if (latitude && longitude) {
    let markers = null;
    if (showCenterMarker) {
      const icon = createCrossIcon();
      markers = (
        <MarkerClusterGroup
        // disableClusteringAtZoom={1}
        // singleMarkerMode={true}
        >
          <Marker position={[latitude, longitude]} icon={icon}></Marker>
        </MarkerClusterGroup>
      );
    }
    mc = (
      <StyledMapContainer
        width={width}
        height={height}
        scrollWheelZoom={false}
        center={[latitude, longitude]}
        zoom={zoom}
        zoomControl={false}
        attributionControl={false}
        dragging={false}
        doubleClickZoom={false}
        boxZoom={false}
        keyboard={false}
        touchZoom={false}
        ref={setMap}
      >
        {newTL}
        {markers}
      </StyledMapContainer>
    );
  }

  useEffect(() => {
    if (map) {
      map.flyTo([latitude, longitude], zoom, FLY_TO_NO_ANIMATION_SETTINGS);
    }
  }, [latitude, longitude, map, zoom]);

  const debugInfo =
    true === showDebug ? (
      <small>
        {latitude}/{longitude} ({width} x {height})
      </small>
    ) : null;

  return (
    <div>
      {mc}
      {debugInfo}
    </div>
  );
}

export default LeafletImage;
