import { Paper } from "@mui/material";
import styled from "styled-components";

export const StyledContent = styled(Paper)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  height: ${({ theme }) => theme.dimensions.content.height};
  padding: ${({ theme }) => theme.dimensions.content.padding};
  /* background-color: var(--bg-color); */
  /* color: ${({ theme }) => theme.colors.content.color}; */
  h1 {
    /* color: ${({ theme }) => theme.colors.content.color}; */
  }
  &:hover {
    /* background-color: ${({ theme }) => theme.colors.content.bgHover}; */
  }
`;
