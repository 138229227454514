import { Avatar, Chip } from "@mui/material";
import React from "react";
import SmartTooltip from "../others/SmartTooltip";

interface IProps {
  text: string;
  icon?: any;
  avatarSrc?: string;
  tooltip?: any;
  color?:
    | "default"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning";
  variant?: "filled" | "outlined";
  href?: string;
  target?: "_self" | "_blank";
  onClick?: any;
  disabled?: boolean;
}
export function SmartChip({
  text,
  avatarSrc,
  icon,
  tooltip,
  color,
  variant,
  href,
  target,
  onClick,
  disabled = false,
}: IProps): JSX.Element | null {
  // const { navigate } = useAppService();
  let innerChip = null;
  const avatar =
    avatarSrc != null ? <Avatar alt={text} src={avatarSrc} /> : undefined;
  if (onClick != null) {
    let newColor = color;
    if (newColor == null) {
      newColor = target === "_blank" ? "default" : "primary";
    }
    innerChip = (
      <Chip
        key={"Chip__" + text}
        icon={icon}
        avatar={avatar}
        label={text}
        color={newColor}
        variant={variant != null ? variant : "filled"}
        clickable={onClick != null || href != null}
        onClick={(event: any) => {
          onClick(event);
        }}
        disabled={disabled}
        // cursor="default"
      />
    );
  } else if (href != null) {
    let newColor = color;
    if (newColor == null) {
      newColor = target === "_blank" ? "default" : "primary";
    }

    innerChip = (
      <Chip
        key={"Chip__" + text}
        icon={icon}
        avatar={avatar}
        label={text}
        color={newColor}
        variant={variant != null ? variant : "filled"}
        // component={Test}
        clickable={true}
        // onClick={(event) => {
        //   if (target === "_blank") {
        //     window.open(href, target);
        //   } else {
        //     navigate(href);
        //   }
        //   event.stopPropagation();
        // }}
        component="a"
        href={href}
        target={target}
        disabled={disabled}
      />
    );
  } else {
    const newColor = color != null ? color : "info";
    innerChip = (
      <Chip
        key={"Chip__" + text}
        icon={icon}
        avatar={avatar}
        label={text}
        variant={variant != null ? variant : "outlined"}
        color={newColor}
        clickable={onClick != null}
        onClick={(event) => {
          if (onClick != null) {
            onClick(event);
          }
        }}
        disabled={disabled}
      />
    );
  }
  if (tooltip != null) {
    return <SmartTooltip title={tooltip}>{innerChip}</SmartTooltip>;
  }
  return innerChip;
}
