import { Tooltip } from "recharts";

export function CustomRechartsTooltip({ active, payload, label }) {
  // function formatTooltip(value, name, props) {
  //   return value;
  // }
  function formatTooltipLabel(label, payload) {
    let text = label;
    const date = new Date(label);
    if (date != null) {
      text = date.toLocaleString("de-DE", {
        dateStyle: "short",
        timeStyle: "short",
      });
    }
    return <p>{text}</p>;
  }

  return (
    <div>
      <Tooltip
        active={active}
        payload={payload}
        label={label}
        // formatter={formatTooltip}
        labelFormatter={formatTooltipLabel}
      />
    </div>
  );
}
