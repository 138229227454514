import React, { useEffect, useState } from "react";
import { getFeatureConstFromKey } from "../../api/ApiUtil";
import { compareFeatures } from "../../api/FeaturesConstants";
import useTranslationService from "../../services/TranslationService";
import { isJSONString } from "../../utils/Utils";
import MultiCheckboxComponent from "../others/MultiCheckboxComponent";
import { INITIAL_FEATURES_OPTIONS } from "../places/PlacesConstants";

export function FeatureSettingsForm(): JSX.Element | null {
  const { t } = useTranslationService();

  function initFeatures() {
    const storedFeatures = localStorage.getItem("features");
    const initialFeaturesSorted =
      INITIAL_FEATURES_OPTIONS.sort(compareFeatures);
    let ret = initialFeaturesSorted;
    if (storedFeatures != null && isJSONString(storedFeatures)) {
      const storedFeatureArray = JSON.parse(storedFeatures);
      ret = initialFeaturesSorted.map((f) => {
        const domain = f.domain;
        const storedFilterd = storedFeatureArray.filter(
          (o: { domain: string }) => {
            return domain === o.domain;
          }
        );
        if (storedFilterd.length > 0) {
          const theOne = storedFilterd[0];
          if (theOne.checked != null) {
            f.checked = theOne.checked;
          }
        }
        return f;
      });
    }
    return ret;
  }
  const [features, setFeatures] = useState(initFeatures);

  useEffect(() => {
    const stringifiedFeatures = JSON.stringify(features);
    localStorage.setItem("features", stringifiedFeatures);
  }, [features]);
  const featureEntries = _features2CheckboxElements(features);

  function changeFeatures(elements: any) {
    setFeatures(_checkboxElements2Features(elements));
  }

  function _features2CheckboxElements(features: any[]) {
    const ret = [];
    for (let i = 0; i < features.length; i++) {
      const f = features[i];
      let name = f.domain;
      let icon = null;
      const fc = getFeatureConstFromKey(f.domain);
      if (fc != null) {
        icon = fc.icon;
        const tk = fc.translationKey;
        if (tk == null) {
          break;
        }
        name = t(tk);
      }
      const newElement = {
        id: f.domain,
        label: name,
        checked: f.checked,
        icon: icon,
      };
      ret.push(newElement);
    }
    return ret;
  }
  function _checkboxElements2Features(checkboxElements: any[]) {
    const ret = [];
    for (let i = 0; i < checkboxElements.length; i++) {
      const e = checkboxElements[i];
      const newServices = { domain: e.id, name: e.label, checked: e.checked };
      ret.push(newServices);
    }
    return ret;
  }

  return (
    <MultiCheckboxComponent
      key="Features"
      entries={featureEntries}
      onChange={changeFeatures}
    />
  );
}
