import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useGetAllCountriesQuery } from "../../api/PlacesApiSlice.js";
import useTranslationService from "../../services/TranslationService.tsx";
import { isArrayOfStrings, isJSONString } from "../../utils/Utils.tsx";
import SmartAccordion from "../accordion/SmartAccordion";
import {
  INITIAL_PROVIDER_OPTIONS,
  MAX_PLACE_OPTIONS,
  MAX_PRICE_OPTIONS,
} from "../places/PlacesConstants.ts";
import { useUserService } from "./../../services/UserService";
import { mergeArraysToNewArray } from "./../../utils/ArrayUtils2";
import MultiCheckboxComponent from "./../others/MultiCheckboxComponent";
import { LanguageSwitch } from "./LanguageSwitch";
import { FeatureSettingsForm } from "./FeatureSettingsForm";

export function SettingsForm() {
  const { t, tCountryNameFromCountryCode } = useTranslationService();
  const storedMaxPlaceCount = localStorage.getItem("maxPlaceCount");
  const [maxPlaceCount, setMaxPlaceCount] = useState(
    storedMaxPlaceCount ? storedMaxPlaceCount : MAX_PLACE_OPTIONS[2].value
  );
  const { isSuperuser } = useUserService();

  const storedCountriesString = localStorage.getItem("countries");
  const storedCountries = _cleanupStoredCountries(
    JSON.parse(storedCountriesString)
  );
  const [countries, setCountries] = useState(
    storedCountries ? storedCountries : null
  );

  const storedMaxPrice = localStorage.getItem("maxPrice");
  const [maxPrice, setMaxPrice] = useState(
    storedMaxPrice
      ? storedMaxPrice
      : MAX_PRICE_OPTIONS[MAX_PRICE_OPTIONS.length - 1].value
  );

  const storedShowPlacesWithoutPriceInfo = localStorage.getItem(
    "showPlacesWithoutPriceInfo"
  );
  const [showPlacesWithoutPriceInfo, setShowPlacesWithoutPriceInfo] = useState(
    storedShowPlacesWithoutPriceInfo != null
      ? JSON.parse(storedShowPlacesWithoutPriceInfo)
      : true
  );

  const storedShowPlacesWithoutAttachment = localStorage.getItem(
    "showPlacesWithoutAttachment"
  );
  const [showPlacesWithoutAttachment, setShowPlacesWithoutAttachment] =
    useState(
      storedShowPlacesWithoutAttachment != null
        ? JSON.parse(storedShowPlacesWithoutAttachment)
        : true
    );

  const storedInfoFlag = localStorage.getItem("info");
  const [infoFlag, setInfoFlag] = useState(
    storedInfoFlag !== undefined ? JSON.parse(storedInfoFlag) : false
  );

  const storedProviders = localStorage.getItem("providers");
  const [providers, setProviders] = useState(
    storedProviders && isJSONString(storedProviders)
      ? updateArray(
          INITIAL_PROVIDER_OPTIONS,
          JSON.parse(storedProviders),
          "domain"
        )
      : INITIAL_PROVIDER_OPTIONS
  );

  const { data: allCountries } = useGetAllCountriesQuery();

  // useEffect(() => {
  //   if (allCountries && allCountries.length > 0) {
  //     const newOptions = allCountries.map((c) => ({
  //       key: c,
  //       value: c,
  //       label: c,
  //     }));
  //     setCountryOptions(newOptions);
  //   }
  // }, [allCountries]);

  function changeProvider(elements) {
    setProviders(_checkboxElements2Providers(elements));
  }
  function changeCountries(elements) {
    const currentCountries = _checkboxElements2Countries(elements);
    setCountries(currentCountries);
    const stringifiedCountries = JSON.stringify(currentCountries);
    localStorage.setItem("countries", stringifiedCountries);
  }
  function changeShowPlacesWithoutPriceInfo(e) {
    if (e && e.target) {
      const target = e.target;
      const checked = target.checked;
      setShowPlacesWithoutPriceInfo(checked);
    }
  }
  function changeShowPlacesWithoutAttachment(e) {
    if (e && e.target) {
      const target = e.target;
      const checked = target.checked;
      setShowPlacesWithoutAttachment(checked);
    }
  }
  function changeInfoFlag(e) {
    if (e && e.target) {
      const target = e.target;
      const checked = target.checked;
      setInfoFlag(checked);
      const stringified = JSON.stringify(checked);
      localStorage.setItem("info", stringified);
    }
  }

  useEffect(() => {
    localStorage.setItem("maxPlaceCount", maxPlaceCount);
    localStorage.setItem("maxPrice", maxPrice);
    localStorage.setItem(
      "showPlacesWithoutPriceInfo",
      showPlacesWithoutPriceInfo
    );
    localStorage.setItem(
      "showPlacesWithoutAttachment",
      showPlacesWithoutAttachment
    );
    const stringifiedProviders = JSON.stringify(providers);
    localStorage.setItem("providers", stringifiedProviders);
  }, [
    maxPlaceCount,
    maxPrice,
    showPlacesWithoutPriceInfo,
    providers,
    showPlacesWithoutAttachment,
  ]);
  const providerEntries = _providers2CheckboxElements(providers);
  const countryEntries = _countries2CheckboxElements(allCountries, countries);

  function _countries2CheckboxElements(allCountryKeys, checkedCountries) {
    const ret = [];
    if (allCountryKeys) {
      for (let i = 0; i < allCountryKeys.length; i++) {
        const countryKey = allCountryKeys[i];
        const checked =
          checkedCountries && checkedCountries.includes(countryKey);
        const newElement = {
          id: countryKey,
          label: tCountryNameFromCountryCode(countryKey),
          checked: checked,
        };
        ret.push(newElement);
      }
    }
    return ret;
  }

  return (
    <>
      <SmartAccordion header={t("language")} defaultExpanded={true}>
        <FormControl fullWidth>
          <InputLabel id="langauge-select-label">
            {t("current-language")}
          </InputLabel>
          <LanguageSwitch />
        </FormControl>
      </SmartAccordion>
      <SmartAccordion header={t("number")} defaultExpanded={true}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            {t("maximum-places-displayed")}
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={maxPlaceCount}
            label={t("maximum-places-displayed")}
            onChange={(e) => setMaxPlaceCount(e.target.value)}
          >
            {MAX_PLACE_OPTIONS &&
              MAX_PLACE_OPTIONS.map((o) => (
                <MenuItem key={o.value} value={o.value}>
                  {o.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </SmartAccordion>
      <SmartAccordion header={t("features")} defaultExpanded={true}>
        <FeatureSettingsForm key="Features" />
      </SmartAccordion>
      <SmartAccordion header={t("provider")} defaultExpanded={true}>
        <MultiCheckboxComponent
          key="Anbieter"
          entries={providerEntries}
          onChange={changeProvider}
        />
      </SmartAccordion>
      <SmartAccordion header={t("price")} defaultExpanded={true}>
        <FormControl fullWidth>
          <InputLabel id="maxPrice-select-label">
            {t("maximum-price")}
          </InputLabel>
          <Select
            labelId="maxPrice-select-label"
            id="maxPrice-select"
            value={maxPrice}
            label={t("maximum-price")}
            onChange={(e) => setMaxPrice(e.target.value)}
          >
            {MAX_PRICE_OPTIONS &&
              MAX_PRICE_OPTIONS.map((o) => (
                <MenuItem key={o.key} value={o.value}>
                  {o.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={showPlacesWithoutPriceInfo}
              onChange={changeShowPlacesWithoutPriceInfo}
            />
          }
          label={t("show-places-without-price-information")}
        />
      </SmartAccordion>
      {isSuperuser ? (
        <SmartAccordion header={t("attachments")} defaultExpanded={true}>
          <FormControlLabel
            control={
              <Checkbox
                checked={showPlacesWithoutAttachment}
                onChange={changeShowPlacesWithoutAttachment}
              />
            }
            label={t("show-places-without-attachments")}
          />
        </SmartAccordion>
      ) : null}
      <SmartAccordion header={t("countries")} defaultExpanded={false}>
        <MultiCheckboxComponent
          key="countries"
          entries={countryEntries}
          onChange={changeCountries}
        />
      </SmartAccordion>
      {isSuperuser && (
        <SmartAccordion header={t("debug")} defaultExpanded={true}>
          <FormControlLabel
            control={<Checkbox checked={infoFlag} onChange={changeInfoFlag} />}
            label={t("show-debug-information")}
          />
        </SmartAccordion>
      )}
    </>
  );
}

function updateArray(oldArray, newArray, key) {
  let ret = mergeArraysToNewArray(newArray, oldArray, key);
  ret = ret.filter((item) => {
    const k = item[key];
    const filtered = newArray.filter((e) => {
      return k === e[key];
    });
    return filtered.length > 0;
  });
  return ret;
}

function _providers2CheckboxElements(providers) {
  const ret = [];
  for (let i = 0; i < providers.length; i++) {
    const p = providers[i];
    const newElement = { id: p.domain, label: p.name, checked: p.checked };
    ret.push(newElement);
  }
  return ret;
}
function _checkboxElements2Providers(checkboxElements) {
  const ret = [];
  for (let i = 0; i < checkboxElements.length; i++) {
    const e = checkboxElements[i];
    const newProvider = { domain: e.id, name: e.label, checked: e.checked };
    ret.push(newProvider);
  }
  return ret;
}
function _checkboxElements2Countries(checkboxElements) {
  const ret = [];
  if (checkboxElements) {
    for (let i = 0; i < checkboxElements.length; i++) {
      const e = checkboxElements[i];
      const checked = e.checked ? true : false;
      if (checked) {
        const newCountry = e.id;
        ret.push(newCountry);
      }
    }
  }
  return ret;
}

function _cleanupStoredCountries(countries) {
  if (!countries) {
    return [];
  }
  if (isArrayOfStrings(countries)) {
    return countries;
  }
  return [];
}
