import { Stack, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import SmartAccordionLazy from "../components/accordion/SmartAccordionLazy";
import SmartMap from "../components/maps/SmartMap";
import GenericInfoComponent from "../components/others/GenericInfoComponent";
import PlacesList from "../components/places/PlacesList";
import { useUserService } from "../services/UserService";
import { isDebugInfo } from "../utils/AppUtils";
import { StyledContent } from "./../components/styles/styled.Content";

function MyFavoritesPage() {
  const { t } = useTranslation();
  const { favoritePlaces, userDataObject, isServiceReady } = useUserService();
  if (!isServiceReady) {
    return <Typography>{t("loading")}</Typography>;
  }
  let theContent = (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Typography>{t("no-favorites-saved")}</Typography>
    </Stack>
  );
  if (favoritePlaces != null && favoritePlaces.length > 0) {
    theContent = (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="stretch"
        spacing={2}
      >
        <Typography>
          {t("count-favorites-saved", { count: favoritePlaces.length })}
        </Typography>
        <SmartAccordionLazy
          header={t("map")}
          childrenCallback={function (): JSX.Element | JSX.Element[] {
            return <SmartMap places={favoritePlaces} />;
          }}
        />
        <PlacesList places={favoritePlaces} infoVisibility="collapsed" />{" "}
      </Stack>
    );
  }
  return (
    <StyledContent>
      {theContent}
      {isDebugInfo() && (
        <GenericInfoComponent dataObject={userDataObject} open={false} />
      )}
    </StyledContent>
  );
}

export default MyFavoritesPage;
