import { DeleteForeverTwoTone } from "@mui/icons-material";
import React from "react";
import { useCheckIfPlaceIsDeleted } from "../../services/PlaceService";
import useTranslationService from "../../services/TranslationService";
import { IPlace } from "../../stuff/AppModelTypes";
import { SmartChip } from "../chip/SmartChip";

type Props = {
  place: IPlace;
};

export function DeletedChip({ place }: Props) {
  const { tCapitalize } = useTranslationService();
  let ret = null;
  const deleted = useCheckIfPlaceIsDeleted(place);
  if (deleted) {
    ret = (
      <SmartChip
        icon={<DeleteForeverTwoTone />}
        text={tCapitalize("deleted")}
        // href={url != null ? url : undefined}
        // target="_blank"
        tooltip={tCapitalize("this_place_has_been_deleted")}
        color="error"
        variant="filled"
      />
    );
  }
  return ret;
}
