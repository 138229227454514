import { createContext } from 'react';


type TC = {
  title: string,
  setTitle: any,
};

export const TitleContext = createContext<TC | undefined>(undefined);

// export function useTitleContext() {
//   const title = useContext(TitleContext);

//   if (title === undefined) {
//     throw new Error('useTitleContext must be used with a TitleContext');
//   }

//   return title;
// }