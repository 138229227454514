import { skipToken } from "@reduxjs/toolkit/dist/query";
import React from "react";
import { useGetRelationsPlace2Placelists } from "../../services/RelationsServices";
import { IPlace } from "../../stuff/AppModelTypes";
import { PlacelistsChip } from "./PlacelistsChip";

type Props = {
  place: IPlace;
};

export function PlacelistsChips({ place }: Props): JSX.Element | null {
  // const placelists = useGetPlacelistsForPlace(place ?? skipToken);
  const relations = useGetRelationsPlace2Placelists(place ?? skipToken);
  let ret = null;

  if (relations != null) {
    ret = (
      <>
        {relations.map((relation: any) => {
          return <PlacelistsChip relation={relation} />;
        })}
      </>
    );
  }
  return ret;
}
