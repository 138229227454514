
export function generateSXForTextBorder(lineWidth: string, blur: string, color: string,) {
  function createOne(n: number) {
    let first = lineWidth;
    if ([3, 4, 5].indexOf(n) >= 0) {
      first = '-' + lineWidth;
    } else if ([6, 7].indexOf(n) >= 0) {
      first = '0';
    }
    let second = lineWidth;
    if ([1, 4, 7].indexOf(n) >= 0) {
      second = '-' + lineWidth;
    } else if ([2, 5].indexOf(n) >= 0) {
      second = '0';
    }
    return first + ' ' + second + ' ' + blur + ' ' + color;
  }
  let ret = createOne(0);
  let i = 1;
  while (i < 8) {
    ret = ret + ', ' + createOne(i);
    i++;
  }
  return ret;
};
