import { Dialog } from "@mui/material";
import React from "react";
import { useMediaQuery } from "react-responsive";

interface IProps {
  open: boolean;
  children?: React.ReactNode;
  closeOnBackdropClick?: boolean;
  onClose?: () => void;
  // onSubmit: () => void;
}

export function BetterDialog({
  open,
  children,
  closeOnBackdropClick = true,
  onClose,
}: // onSubmit,
IProps): JSX.Element | null {
  const isSmallScreen = useMediaQuery({ query: "(max-width: 600px)" });

  function handleDialogClose(event: any) {
    if (onClose != null) {
      onClose();
    }
    return false;
  }

  return (
    <Dialog
      open={open}
      children={children}
      fullScreen={isSmallScreen}
      fullWidth
      onClose={(event: any, reason) => {
        if (closeOnBackdropClick || reason !== "backdropClick") {
          handleDialogClose(event);
        }
        event.stopPropagation();
        event.preventDefault();
      }}
      PaperProps={{
        // PaperProps are needed to prohibit the clicking "through" the dialog
        onClick: (e: any) => {
          e.stopPropagation();
        },
      }}
      // onBackdropClick={(e) => {
      //   e.stopPropagation();
      //   e.preventDefault();
      // }}
    />
  );
}
