import { TuneTwoTone } from "@mui/icons-material";
import React from "react";
import { AiTwotoneExperiment } from "react-icons/ai";
import { CgMenuGridR } from "react-icons/cg";
import { FaBeer, FaList, FaMap, FaMapPin } from "react-icons/fa";
import AccountInfoPage from "../pages/AccountInfoPage";
import MyPlacelistsPage from "../pages/MyPlacelistsPage";
import { PlacesListPage } from "../pages/PlacesListPage";
import SearchLocationPage from "../pages/SearchLocationPage";
import { StatisticsForSuperuserPage } from "../pages/StatisticsForSuperuserPage";
import { StatisticsPage } from "../pages/StatisticsPage";
import { TestPage1 } from "../pages/TestPage1";
import { TestPage2 } from "../pages/TestPage2";
import { NewPlacesPage } from "../pages/news_n_updates/NewPlacesPage";
import { NewScrapeLinksPage } from "../pages/news_n_updates/NewScrapeLinksPage";
import { NewestPlacesPage } from "../pages/news_n_updates/NewestPlacesPage";
import { LostPlacesPage } from "../pages/LostPlacesPage";
import { MapPage } from "../parts/content/MapPage";
import { SettingsPage } from "./../pages/SettingsPage";
import MyFavoritesPage from "../pages/MyFavoritesPage";
import { PlacelistsListPage } from "./../pages/PlacelistsListPage";

export const APP_NAME = "Places and more";

export const FLY_TO_ANIMATION_SETTINGS = {
  animate: true,
  duration: 6,
};

export const FLY_TO_NO_ANIMATION_SETTINGS = {
  animate: false,
};

export enum VisibleE {
  always = "ALWAYS",
  never = "NEVER",
  if_logged_in = "IF LOGGED IN",
  if_superuser = "IF SUPERUSER",
}
const TEST_VERSION: VisibleE = VisibleE.always;
export const SHOW_LOGIN = TEST_VERSION;
export const SHOW_FOOTER_ENTRIES = TEST_VERSION;

export type IMenuEntry = {
  // name?: string;
  // tooltip?: string;
  nameKey: string;
  tooltipKey?: string;
  link: string;
  icon?: any;
  image?: string;
  mainMenu: VisibleE;
  kachel: VisibleE;
  react?: React.JSX.Element;
  weight?: number;
  authNeeded?: boolean;
};
export const MENU_STRUCTURE: IMenuEntry[] = [
  {
    nameKey: "home",
    tooltipKey: "main-menu",
    link: "menu",
    icon: CgMenuGridR,
    mainMenu: VisibleE.always,
    kachel: VisibleE.never,
  },
  {
    nameKey: "places",
    tooltipKey: "show-list",
    link: "list",
    icon: FaList,
    image: "/images/kachel/screenshot_list.png",
    mainMenu: VisibleE.always,
    kachel: VisibleE.always,
    react: <PlacesListPage />,
  },
  {
    nameKey: "place-lists",
    tooltipKey: "show-place-lists",
    link: "placelists",
    icon: FaList,
    image: "/images/kachel/screenshot_list.png",
    mainMenu: VisibleE.always,
    kachel: VisibleE.always,
    react: <PlacelistsListPage />,
  },
  {
    nameKey: "search-location",
    tooltipKey: "search-location",
    link: "searchlocation",
    icon: FaMapPin,
    image: "/images/kachel/screenshot_searchlocation.png",
    mainMenu: VisibleE.always,
    kachel: VisibleE.always,
    react: <SearchLocationPage />,
  },
  {
    nameKey: "map",
    tooltipKey: "show-map",
    link: "map",
    icon: FaMap,
    image: "/images/kachel/screenshot_map.png",
    mainMenu: VisibleE.always,
    kachel: VisibleE.always,
    react: <MapPage />,
  },
  {
    nameKey: "my-favorites",
    tooltipKey: "show-my-favorites",
    link: "favorites",
    icon: FaList,
    // image: "/images/kachel/screenshot_list.png",
    mainMenu: VisibleE.if_logged_in,
    kachel: VisibleE.if_logged_in,
    react: <MyFavoritesPage />,
    authNeeded: true,
  },
  {
    nameKey: "my-place-lists",
    tooltipKey: "show-my-place-lists",
    link: "myplacelists",
    icon: FaList,
    // image: "/images/kachel/screenshot_list.png",
    mainMenu: VisibleE.if_logged_in,
    kachel: VisibleE.if_logged_in,
    react: <MyPlacelistsPage />,
  },
  {
    nameKey: "newest-places",
    tooltipKey: "show-newest-places",
    link: "newestplaces",
    icon: FaList,
    // image: "/images/kachel/screenshot_list.png",
    mainMenu: VisibleE.always,
    kachel: VisibleE.always,
    react: <NewestPlacesPage />,
  },
  {
    nameKey: "new-places",
    tooltipKey: "show-new-places",
    link: "newplaces",
    icon: FaList,
    // image: "/images/kachel/screenshot_list.png",
    mainMenu: VisibleE.if_superuser,
    kachel: VisibleE.if_superuser,
    react: <NewPlacesPage />,
  },
  {
    nameKey: "new-scrape-links",
    tooltipKey: "show-new-scrape-links",
    link: "newscrapelinks",
    icon: FaList,
    // image: "/images/kachel/screenshot_list.png",
    mainMenu: VisibleE.if_superuser,
    kachel: VisibleE.if_superuser,
    react: <NewScrapeLinksPage />,
  },
  {
    nameKey: "SU stats",
    tooltipKey: "show statistics for superusers",
    link: "stats4su",
    icon: FaList,
    // image: "/images/kachel/screenshot_list.png",
    mainMenu: VisibleE.if_superuser,
    kachel: VisibleE.if_superuser,
    react: <StatisticsForSuperuserPage />,
  },
  {
    nameKey: "lost-places",
    tooltipKey: "lost-places",
    link: "lostplaces",
    icon: AiTwotoneExperiment,
    mainMenu: VisibleE.if_superuser,
    kachel: VisibleE.if_superuser,
    react: <LostPlacesPage />,
  },
  {
    nameKey: "settings",
    tooltipKey: "change-settings",
    link: "settings",
    icon: TuneTwoTone,
    image: "/images/kachel/screenshot_settings.png",
    mainMenu: VisibleE.always,
    kachel: VisibleE.always,
    react: <SettingsPage />,
  },
  {
    nameKey: "account",
    tooltipKey: "show-account",
    link: "myaccount",
    icon: TuneTwoTone,
    // image: "/images/kachel/screenshot_settings.png",
    mainMenu: VisibleE.always,
    kachel: VisibleE.always,
    react: <AccountInfoPage />,
  },
  {
    nameKey: "statistics",
    tooltipKey: "view-statistics",
    link: "statistics",
    icon: FaBeer,
    mainMenu: VisibleE.if_superuser,
    kachel: VisibleE.if_superuser,
    react: <StatisticsPage />,
  },
  // {
  //   name: "Tests",
  //   tooltip: "Tests",
  //   link: "tests",
  //   icon: AiTwotoneExperiment,
  //   mainMenu: VisibleE.if_superuser,
  //   kachel: VisibleE.if_superuser,
  // },
  {
    nameKey: "test",
    tooltipKey: "test",
    link: "test1",
    icon: AiTwotoneExperiment,
    mainMenu: VisibleE.if_superuser,
    kachel: VisibleE.if_superuser,
    react: <TestPage1 />,
  },
  {
    nameKey: "test",
    tooltipKey: "test",
    link: "test2",
    icon: AiTwotoneExperiment,
    mainMenu: VisibleE.if_superuser,
    kachel: VisibleE.if_superuser,
    react: <TestPage2 />,
  },
];
