import { useTranslation } from "react-i18next";
import { useRouteError } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export function ErrorPage() {
  const { t } = useTranslation();
  const error = useRouteError();
  console.error(error);

  return (
    <Container id="error-page">
      <h1>Oops!</h1>
      <p>{t("sorry-an-unexpected-error-has-occurred")}</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </Container>
  );
}
