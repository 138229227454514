import React from "react";
import { PETS_ALLOWED } from "../../../api/FeaturesConstants";
import { FeatureChip } from "./FeatureChip";

type Props = {
  feature?: any;
};

export function PetsAllowedChip({ feature }: Props) {
  return <FeatureChip feature={feature} featureConst={PETS_ALLOWED} />;
}
