import { divIcon } from "leaflet";

export enum MarkerVisualizations {
  "dot",
  "circle",
}

export function createSvgStringforCircle(size: number, options?: any) {
  const DEFAULT_OPTIONS = { color: "blue", filled: false, opacity: "0.8" };
  const opts = { ...DEFAULT_OPTIONS, ...options };
  const half = size / 2;
  const stroke = half / 4;
  const radius = half - stroke;
  return (
    `
  <svg cursor="default" height="` +
    size +
    `" width="` +
    size +
    `" xmlns="http://www.w3.org/2000/svg">
            <circle r="` +
    radius +
    `" cx="` +
    half +
    `" cy="` +
    half +
    `" fill=` +
    (opts.filled ? opts.color : "none") +
    ` stroke=` +
    opts.color +
    ` stroke-width="` +
    stroke +
    `" opacity=` +
    opts.opacity +
    ` />`
  );
}

export function createMarkerIcon(
  mapSize: number,
  markerVisualization: MarkerVisualizations
) {
  const svgSize =
    markerVisualization === MarkerVisualizations.circle
      ? mapSize / 8
      : mapSize / 16;
  return divIcon({
    html: createSvgStringforCircle(svgSize, {
      filled: markerVisualization === MarkerVisualizations.dot ? true : false,
    }),
    className: "svg-icon",
    iconAnchor: [svgSize / 2, svgSize / 2],
  });
}
