import { IPlace } from "../../stuff/AppModelTypes";
import { isJSONString } from "../../utils/Utils";

export type Coordinates = {
  lat: number;
  lon: number;
};

export function getCoordinatesForPlace(place: IPlace): Coordinates | null {
  if (place != null && place.Latitude != null && place.Longitude != null) {
    return {
      lon: place.Longitude,
      lat: place.Latitude,
    };
  } else {
    return null;
  }
}

export function getCoordinatesTextForPlace(
  place: IPlace,
  fixed?: number
): string | null {
  let ret = null;
  const coordinates = getCoordinatesForPlace(place);
  if (
    coordinates != null &&
    coordinates.lat != null &&
    coordinates.lon != null
  ) {
    ret = getCoordinatesText(coordinates, fixed);
  }
  return ret;
}

export function getCoordinatesText(
  coordinates: Coordinates,
  fixed?: number
): string {
  let lat: number | string = coordinates.lat;
  let lon: number | string = coordinates.lon;
  if (fixed != null) {
    lat = lat.toFixed(fixed);
    lon = lon.toFixed(fixed);
  }
  return lat + ", " + lon;
}

export function getTooltipTextForCoords(
  coords: Coordinates,
  inaccurate?: boolean
): string | null {
  if (inaccurate == null) {
    inaccurate = false;
  }
  if (coords != null) {
    return (
      "Direkt zu Google Maps" +
      (inaccurate
        ? " (ungenau, da Anbieter die exakten Koordinaten erst nach Buchung freigibt)"
        : " (" + getCoordinatesText(coords) + ")")
    );
  }
  return null;
}

export function getGoogleMapsUrlForCoords(coords: Coordinates): string | null {
  if (coords != null) {
    return (
      "https://www.google.com/maps/search/?api=1&query=" +
      coords.lat +
      "%2C" +
      coords.lon
    );
  }
  return null;
}

export function isLocationInaccurateForPlace(place: IPlace) {
  const placeData = isJSONString(place.Data) ? JSON.parse(place.Data) : null;
  return placeData != null && placeData.gps_location === "inaccurate";
}
