import { Tooltip, TooltipProps } from "@mui/material";
import React from "react";
import styled from "styled-components";

const SmartTooltip = styled((props: TooltipProps) => (
  <Tooltip
    classes={{ popper: props.className }}
    PopperProps={{ style: { zIndex: 9999 } }}
    followCursor
    arrow
    {...props}
  >
    <div>{props.children}</div>
  </Tooltip>
))`
  & .MuiTooltip-tooltip {
    // background-color: papayawhip;
    // color: #000;
  }
`;

export default SmartTooltip;
