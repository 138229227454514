import "leaflet/dist/leaflet.css";
import React from "react";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import styled from "styled-components";
import { IBound, ICoord, boundToLatLngBoundsExpression } from "./MapUtils";
import { MarkerVisualizations, createMarkerIcon } from "./SvgUtils";

const StyledMapContainer = styled(MapContainer)<{
  width: string;
  height: string;
}>`
  display: flex;
  flex-direction: column;
  width: ${(props: any) => {
    return props.width ? props.width : "400px";
  }};
  height: ${(props: any) => {
    return props.height ? props.height : "300px";
  }};
  flex-grow: 1;
  flex-shrink: 1;
`;

interface IProps {
  bounds: IBound;
  locations: ICoord[];
  width: string;
  height: string;
  markerVisualization?: MarkerVisualizations;
  disableMarkerClustering?: boolean;
  showDebug?: boolean;
}

function LocationsInAreaImage({
  bounds,
  locations,
  width,
  height,
  markerVisualization = MarkerVisualizations.dot,
  disableMarkerClustering = false,
  showDebug = false,
}: IProps): JSX.Element | null {
  // const [map, setMap] = useState<any | null>(null);
  const newTL = (
    <TileLayer
      key="tile_layer"
      url="http://services.arcgisonline.com/arcgis/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}.png"
    />
  );

  let mc = null;
  if (locations != null && locations.length > 0) {
    // const lat = location.lat;
    // const lon = location.lon;
    let markers = null;

    const imageSize = Math.min(parseFloat(width), parseFloat(height));
    // if (lat != null && lon != null) {
    const icon = createMarkerIcon(imageSize, markerVisualization);
    markers =
      disableMarkerClustering === true ? (
        locations.map((loc) => {
          return (
            <Marker
              key={"Marker_" + loc.lat + "_" + loc.lon}
              position={[loc.lat, loc.lon]}
              icon={icon}
            />
          );
        })
      ) : (
        <MarkerClusterGroup
          spiderfyOnMaxZoom={false}
          showCoverageOnHover={false}
          zoomToBoundsOnClick={false}
          sx={{ cursor: "default" }}
          // disableClusteringAtZoom={1}
          // singleMarkerMode={true}
        >
          {locations.map((loc) => {
            return <Marker position={[loc.lat, loc.lon]} icon={icon} />;
          })}
        </MarkerClusterGroup>
      );
    // }
    mc = (
      <StyledMapContainer
        bounds={boundToLatLngBoundsExpression(bounds)}
        width={width}
        height={height}
        scrollWheelZoom={false}
        zoomControl={false}
        attributionControl={false}
        dragging={false}
        doubleClickZoom={false}
        boxZoom={false}
        keyboard={false}
        touchZoom={false}
        // ref={setMap}
      >
        {newTL}
        {markers}
      </StyledMapContainer>
    );
  }

  const debugInfo = showDebug ? (
    <>
      <p>
        west: {bounds.west} / east: {bounds.east} / north: {bounds.north} /
        south: {bounds.south}
      </p>
      {locations.map((loc) => {
        return (
          <p>
            {loc.lat}/{loc.lon} ({width} x {height})
          </p>
        );
      })}
      ;
    </>
  ) : null;

  return (
    <div>
      {mc}
      {debugInfo}
    </div>
  );
}

export default LocationsInAreaImage;
