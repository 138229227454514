import { isNullOrUndefined } from "../utils/Utils";
import { FEATURES_ALL } from "./FeaturesConstants";

export function createPlacesFilteringString(props: any) {
  let text = commonFilterString(props);
  if (props) {
    if (props.searchString && props.searchString.length > 0) {
      text = text + "&filter=Name,cs," + props.searchString;
    }
    if (
      props.country &&
      !(props.country === undefined) &&
      !(props.country === "undefined")
    ) {
      text = text + "&filter=Land,eq," + props.country;
    }
    if (
      props.maxPrice &&
      !(props.maxPrice === undefined) &&
      props.maxPrice >= 0
    ) {
      text = text + "&filter=Preis,le," + props.maxPrice;
    }
    if (props.maxSize) {
      text = text + "&size=" + props.maxSize;
    }
    if (
      props.onlyWithLocation &&
      !(props.onlyWithLocation === undefined) &&
      props.onlyWithLocation === true
    ) {
      // eslint-disable-next-line no-useless-concat
      text = text + "&filter=Latitude,nis" + "&filter=Longitude,nis";
    }
    if (props.bounds) {
      const bounds = props.bounds;
      text =
        text +
        "&filter=Latitude,bt," +
        bounds.south +
        "," +
        bounds.north +
        "&filter=Longitude,bt, " +
        bounds.west +
        ", " +
        bounds.east;
    }
    text = text + "&filter=Name,nis";
    text = text + "&filter=Name,neq,";
    text = text + "&order=updated_at,asc";
    const page = props.page;
    if (page && page > 0) {
      text = text + "&page=" + page;
      const pageSize = props.pageSize;
      if (pageSize && pageSize > 0) {
        text = text + "," + pageSize;
      }
    }
  } //if(props)
  return correctFilterString(text);
}

function commonFilterString(props: any) {
  let ret = "";
  if (props != null) {
    // deleted
    if (props.deleted != null) {
      if (props.deleted === true) {
        ret = ret + "&filter=Deleted,eq,1";
      }
      if (props.deleted === false) {
        ret = ret + "&filter=Deleted,is";
      }
    } else {
      ret = ret + "&filter=Deleted,is";
    }
    //providers
    if (props.providers && props.providers.length > 0) {
      const providerList = props.providers;
      let providerListString = null;
      for (let i = 0; i < providerList.length; i++) {
        const p = providerList[i];
        const checked = p.checked;
        if (checked) {
          if (providerListString !== null) {
            providerListString = providerListString + "," + p.domain;
          } else {
            providerListString = p.domain;
          }
        }
      }
      if (providerListString && providerListString.length > 0) {
        ret = ret + "&filter=Domains,in," + providerListString;
      }
    }

    //features
    if (props.features && props.features.length > 0) {
      const featuresList = props.features;
      for (let i = 0; i < featuresList.length; i++) {
        const f = featuresList[i];
        const checked = f.checked;
        if (checked) {
          // ret = ret + "&filter=" + s.domain + ",eq,1";
          ret = ret + "&filter=FeaturesAvailable,cs," + f.domain;
        }
      }
    }
  }
  return ret;
}

export function createFilterString(props: any) {
  let ret = commonFilterString(props);
  let orderString = "&order=updated_at,asc";
  if (props != null) {
    if (props.order && props.order.length > 0) {
      orderString = "&order=" + props.order;
    }
  }
  ret = ret + orderString;
  if (props) {
    if (props.searchString && props.searchString.length > 0) {
      ret = ret + "&filter=Name,cs," + props.searchString;
    }
    if (props.fromUpdatedAt) {
      ret = ret + "&filter=updated_at,ge," + props.fromUpdatedAt;
    }
    if (props.toUpdatedAt) {
      ret = ret + "&filter=updated_at,le," + props.toUpdatedAt;
    }
    if (props.fromCreatedAt) {
      ret = ret + "&filter=created_at,ge," + props.fromCreatedAt;
    }
    if (props.toCreatedAt) {
      ret = ret + "&filter=created_at,le," + props.toCreatedAt;
    }
    if (
      props.maxPrice &&
      !(props.maxPrice === undefined) &&
      props.maxPrice >= 0
    ) {
      ret = ret + "&filter=Preis,le," + props.maxPrice;
    }
    if (props.maxResults) {
      ret = ret + "&size=" + props.maxResults;
    }
    if (
      props.onlyWithLocation &&
      !(props.onlyWithLocation === undefined) &&
      props.onlyWithLocation === true
    ) {
      // eslint-disable-next-line no-useless-concat
      ret = ret + "&filter=Latitude,nis" + "&filter=Longitude,nis";
    }
    if (props.bounds) {
      const bounds = props.bounds;
      ret =
        ret +
        "&filter=Latitude,bt," +
        bounds.south +
        "," +
        bounds.north +
        "&filter=Longitude,bt, " +
        bounds.west +
        ", " +
        bounds.east;
    }
    if (props.countries && props.countries.length > 0) {
      const countryList = props.countries;
      let countryListString = null;
      for (let i = 0; i < countryList.length; i++) {
        const c = countryList[i];
        if (countryListString === null) {
          countryListString = c;
        } else {
          countryListString = countryListString + "," + c;
        }
      }
      if (countryListString && countryListString.length > 0) {
        ret = ret + "&filter=Land,in," + countryListString;
      }
    }
    if (props.ids && props.ids.length > 0) {
      const idListString = createListString(props.ids);
      if (idListString && idListString.length > 0) {
        ret = ret + "&filter=Id,in," + idListString;
      }
    }
    if (props.excludeIds && props.excludeIds.length > 0) {
      const excludeIdListString = createListString(props.excludeIds);
      if (excludeIdListString && excludeIdListString.length > 0) {
        ret = ret + "&filter=Id,nin," + excludeIdListString;
      }
    }
    ret = ret + "&filter=Name,nis";
    ret = ret + "&filter=Land,nis";
    // ret = ret + "&filter=Name,neq,";
    const page = props.page;
    if (!isNullOrUndefined(page) && page > 0) {
      ret = ret + "&page=" + page;
      const pageSize = props.pageSize;
      if (pageSize && pageSize > 0) {
        ret = ret + "," + pageSize;
      }
    }
  } //if(props)
  return correctFilterString(ret);
}

export function createListString(idList: string | any[]) {
  let idListString = null;
  for (let i = 0; i < idList.length; i++) {
    const c = idList[i];
    if (idListString === null) {
      idListString = c;
    } else {
      idListString = idListString + "," + c;
    }
  }
  return idListString;
}

export function correctFilterString(str: string) {
  let ret = str;
  const posOfQuestionMark = str.indexOf("?");
  const posOfAmpersand = str.indexOf("&");
  if (posOfQuestionMark < 0 && posOfAmpersand >= 0) {
    ret = str.replace("&", "?");
  } else if (posOfQuestionMark < posOfAmpersand) {
    return str;
  } else {
    ret = str.replace("&", "?");
  }
  // console.log("post: " + ret);
  return ret;
}

export function getFeatureConstFromKey(key: string) {
  for (let i = 0; i < FEATURES_ALL.length; i++) {
    const f = FEATURES_ALL[i];
    if (key === f.key) {
      return f;
    }
  }
  return null;
}
