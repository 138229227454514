import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { TitleContext } from "../TitleContext";
import useTranslationService from "../services/TranslationService";

interface IProps {
  pageTitle?: string;
  pageTitleKey?: string;
  page: JSX.Element;
}

function PageWrapper({
  pageTitle,
  pageTitleKey,
  page,
}: IProps): JSX.Element | null {
  const titleContext = useContext(TitleContext);
  const { pathname } = useLocation();
  const { tCapitalize } = useTranslationService();

  useEffect(() => {
    let title = pageTitle;
    if (pageTitleKey != null) {
      title = tCapitalize(pageTitleKey);
    }
    if (title != null) {
      titleContext?.setTitle(title);
    }
  }, [pageTitleKey, pageTitle, tCapitalize, titleContext]);

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return page;
}

export default React.memo(PageWrapper);
