import { FEATURES_ALL } from "../../api/FeaturesConstants";

type ElememtString = {
  value: string;
  label: string;
  key?: string;
};

type ElememtNumber = {
  value: number;
  label: string;
  key?: string;
};

export const LANGUAGES: ElememtString[] = [
  { value: "de", label: "German" },
  { value: "en", label: "English" },
  { value: "fr", label: "French" },
  { value: "nl", label: "Dutch" },
  { value: "es", label: "Spanish" },
  { value: "sv", label: "Swedish" },
  { value: "uk", label: "Ukrainian" },
  { value: "el", label: "Greek" },
  { value: "tr", label: "Turkish" },
  { value: "it", label: "Italian" },
  { value: "nb", label: "Norwegian" },
  { value: "pl", label: "Polish" },
  { value: "pt-PT", label: "Portuguese" },
  { value: "ru", label: "Russian" },
  { value: "cy", label: "Welsh" },
];

export const MAX_PLACE_OPTIONS: ElememtNumber[] = [
  { value: 10, label: "max. 10 Plätze" },
  { value: 25, label: "max. 25 Plätze" },
  { value: 50, label: "max. 50 Plätze" },
  { value: 100, label: "max. 100 Plätze" },
  { value: 250, label: "max. 250 Plätze" },
  { value: 500, label: "max. 500 Plätze" },
  { value: 1000, label: "max. 1000 Plätze" },
  { value: 2500, label: "max. 2500 Plätze" },
  { value: 5000, label: "max. 5000 Plätze" },
  { value: 10000, label: "noch mehr Plätze" },
];
MAX_PLACE_OPTIONS.forEach((r: ElememtNumber) => {
  r.key = r.value.toString();
});

export const INITIAL_COUNTRY_OPTIONS: ElememtString[] = [
  { value: "Deutschland", label: "Deutschland" },
  { value: "Frankreich", label: "Frankreich" },
  { value: "Portugal", label: "Portugal" },
];
INITIAL_COUNTRY_OPTIONS.forEach((r: ElememtString) => {
  r.key = r.value !== undefined ? r.value.toString() : r.label;
});

export const MAX_PRICE_OPTIONS: ElememtNumber[] = [
  { value: 0, label: "kostenlos" },
  { value: 1, label: "bis € 1,-" },
  { value: 3, label: "bis € 3,-" },
  { value: 5, label: "bis € 5,-" },
  { value: 7.5, label: "bis € 7,50" },
  { value: 10, label: "bis € 10,-" },
  { value: 15, label: "bis € 15,-" },
  { value: 20, label: "bis € 20,-" },
  { value: 25, label: "bis € 25,-" },
  { value: 30, label: "bis € 30,-" },
  { value: 40, label: "bis € 40,-" },
  { value: 50, label: "bis € 50,-" },
  { value: -1, label: "beliebig" },
];
MAX_PRICE_OPTIONS.forEach((r) => {
  r.key = r.value !== undefined ? r.value.toString() : r.label;
});

export const INITIAL_PROVIDER_OPTIONS: {
  name: string;
  domain: string;
  checked: boolean;
}[] = [
  {
    name: "Alpaca Camping",
    domain: "alpacacamping.de",
    checked: true,
  },
  {
    name: "Camperland",
    domain: "camperland.de",
    checked: true,
  },
  {
    name: "park4night",
    domain: "park4night.com",
    checked: true,
  },
  {
    name: "Pincamp",
    domain: "pincamp.de",
    checked: true,
  },
  {
    name: "Stellplatz.info",
    domain: "stellplatz.info",
    checked: true,
  },
  // {
  //   name: "You and a View",
  //   domain: "you-and-a-view.com",
  //   checked: true,
  // },
];

export const INITIAL_FEATURES_OPTIONS = FEATURES_ALL.map((feature) => {
  return { domain: feature.key, checked: false };
});
