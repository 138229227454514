import React from "react";
import Embed from "react-embed";

interface IProps {
  url: string;
}

function SmartEmbed(props: IProps): JSX.Element | null {
  const comp = <Embed url={props.url} />;
  return (
    <>
      {comp}
      {/* <Typography>{props.url}</Typography> */}
    </>
  );
}

export default SmartEmbed;
