import { useAuth0 } from "@auth0/auth0-react";
import { skipToken } from "@reduxjs/toolkit/dist/query/index.js";
import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import {
  useAddPlaceToUserdataMutation,
  useCreateUserDataMutation,
  useGetUserdataForUserIdQuery,
  useRemovePlaceFromUserdataMutation,
} from "../api/PlacesApiSlice";
import { IUserData } from "../stuff/AppModelTypes";
import { isArray } from "../utils/Utils";

const SUPERUSERS = ["auth0|4", "google-oauth2|114068310225681830256"];

export function useUserService() {
  const [isServiceReady, setServiceReady] = useState(false);
  // const [updateUserData] = useUpdateUserDataMutation();
  const [createUserData] = useCreateUserDataMutation();
  const [addPlaceToUserdata] = useAddPlaceToUserdataMutation();
  const [removePlaceFromUserdata] = useRemovePlaceFromUserdataMutation();
  const {
    user,
    isAuthenticated,
    logout,
    loginWithRedirect,
    isLoading,
    handleRedirectCallback,
  } = useAuth0();
  const userId = user ? user.sub : null;
  const su = userId != null && SUPERUSERS.includes(userId);
  const { data: userDataResult } = useGetUserdataForUserIdQuery(
    userId ?? skipToken
  );
  let ret = {
    user,
    userId,
    userDataResult,
    userDataObject: {},
    userDataId: null,
    isLoading,
    isAuthenticated,
    isSuperuser: su,
    favoritePlaces: [],
    placelists: [],
    isFavoritePlace,
    addFavoritePlace,
    removeFavoritePlace,
    isServiceReady,
    loginWithRedirect,
    handleRedirectCallback,
    logout,
  };

  useEffect(() => {
    if (!isLoading) {
      setServiceReady(true);
    }
  }, [isLoading]);

  useEffect(() => {
    if (
      createUserData != null &&
      userId != null &&
      userDataResult != null &&
      userDataResult.records &&
      userDataResult.records.length < 1
    ) {
      const userDataResultClone = cloneDeep(userDataResult);
      const newUserData = {
        UserId: userId,
        Data: JSON.stringify({}),
      };
      userDataResultClone.records.push(newUserData);
      createUserData(newUserData);
    }
  }, [createUserData, userDataResult, userId]);

  if (userDataResult != null) {
    const userDataObject = _fromUserDataResultToDataObject(userDataResult);
    ret.userDataObject = userDataObject as IUserData;
    if (userDataObject != null) {
      ret.userDataId = userDataObject.Id;
      ret.favoritePlaces = userDataObject.Places;
      ret.placelists = userDataObject.Placelists;
    }
  }

  return ret;

  //****************
  //Inner Functions
  function isFavoritePlace(placeId: any): boolean {
    if (placeId != null) {
      const favArray = _fromUserDataResultToFavoritePlaceIds(userDataResult);
      if (favArray != null) {
        return favArray.some((f: any) => {
          return f === placeId;
        });
      }
    }
    return false;
  }

  function addFavoritePlace(placeId: any) {
    const data = {
      userdata_id: ret.userDataId,
      places_id: placeId,
    };
    addPlaceToUserdata(data);
  }

  function removeFavoritePlace(placeId: any) {
    const userDataObject = ret?.userDataObject as IUserData;
    if (userDataObject != null) {
      const relationElements = userDataObject.userdata_places;
      if (relationElements != null && isArray(relationElements)) {
        for (let index = 0; index < relationElements.length; index++) {
          const element = relationElements[index];
          if (placeId === element.places_id) {
            const relInstanceId = element.Id;
            removePlaceFromUserdata(relInstanceId);
          }
        }
      }
    }
  }
}

//*********************
// HELPER FUNCTIONS
function _fromUserDataResultToFavoritePlaceIds(userDataResult: {
  records: any[];
}) {
  let ret = [];
  const dataObject = _fromUserDataResultToDataObject(userDataResult);
  if (dataObject != null) {
    const places = dataObject.Places;
    for (let i = 0; i < places.length; i++) {
      const p = places[i];
      ret.push(p.Id);
    }
  }
  return ret;
}

function _fromUserDataResultToDataObject(userDataResult: { records: any[] }) {
  if (userDataResult) {
    if (userDataResult.records) {
      if (userDataResult.records[0]) {
        const firstOne = userDataResult.records[0];
        if (firstOne != null) {
          return firstOne;
        }
      }
    }
  }
  return null;
}
