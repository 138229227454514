import { Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { useGetPlacelistQuery } from "../api/PlacesApiSlice";
import SmartLink from "../components/others/SmartLink";
import useTranslationService from "../services/TranslationService";
import PlacelistLarge from "./../components/placelists/PlacelistLarge";
import { StyledContent } from "./../components/styles/styled.Content";

export function MyPlacelistPage() {
  const { t } = useTranslationService();
  const routerParams = useParams();
  let placelistId = routerParams != null ? routerParams.id : null;
  const skip = placelistId == null;
  const { data: placelistQueryResult } = useGetPlacelistQuery(placelistId, {
    skip,
  });

  let placelist = null;
  if (placelistQueryResult != null) {
    placelist = placelistQueryResult.records[0];
  }

  let p = (
    <>
      <Typography>{t("this-place-list-does-not-exist-any-more")}</Typography>
      <SmartLink href="/placelists/" internalLink={true} showAsButton={true}>
        {t("my-place-lists")}
      </SmartLink>
    </>
  );
  if (placelist != null) {
    p = <PlacelistLarge placelist={placelist} />;
  }

  return <StyledContent>{p}</StyledContent>;
}
