import { CommentTwoTone, EditTwoTone } from "@mui/icons-material";
import { Chip, Stack } from "@mui/material";
import React, { useState } from "react";
import useTranslationService from "../../services/TranslationService";
import { useUpdateRelationPlacelistPlaceTextMutation } from "./../../api/PlacesApiSlice";
import ChangeCommentDialog from "./ChangeCommentDialog";
import { useUserService } from "../../services/UserService";
import { usePlacelistsService } from "../../services/PlacelistsService";
import { useCompleteRelationPlaceListPlaces } from "../../services/RelationsServices";
interface IProps {
  relation: any;
}

export function CommentForPlaceinPlacelist({
  relation,
}: IProps): JSX.Element | null {
  const { tCapitalize } = useTranslationService();
  relation = useCompleteRelationPlaceListPlaces(relation);
  let ret = null;
  const { isSuperuser } = useUserService();
  const { isPlacelistEditor } = usePlacelistsService();
  const [changeComment] = useUpdateRelationPlacelistPlaceTextMutation();
  const [comment, setComment] = useState(
    relation != null ? relation.Comment : null
  );
  const [dialogOpen, setDialogOpen] = useState(false);

  const noCommentThere = comment == null || comment.length < 1;
  const fallbackComment = noCommentThere ? tCapitalize("add_note") : comment;
  const editAllowed =
    isSuperuser || isPlacelistEditor(relation.placelists_id.Id);

  const icon = <CommentTwoTone />;
  const deleteIcon = <EditTwoTone />;
  const variant = noCommentThere ? "outlined" : "filled";
  const color = "success";
  const sx = {
    height: "auto",
    py: 1,
    "& .MuiChip-label": {
      display: "block",
      whiteSpace: "normal",
    },
  };
  const clickable = noCommentThere ? true : false;

  let chip = null;
  if (noCommentThere) {
    if (editAllowed) {
      chip = (
        <Chip
          icon={icon}
          variant={variant}
          color={color}
          clickable={clickable}
          onClick={() => {
            setDialogOpen(true);
          }}
          sx={sx}
          label={fallbackComment}
        />
      );
    }
  } else {
    if (editAllowed) {
      chip = (
        <Chip
          icon={icon}
          variant={variant}
          color={color}
          deleteIcon={deleteIcon}
          onDelete={() => {
            setDialogOpen(true);
          }}
          sx={sx}
          label={fallbackComment}
        />
      );
    } else {
      chip = (
        <Chip
          icon={icon}
          variant={variant}
          color={color}
          deleteIcon={deleteIcon}
          sx={sx}
          label={fallbackComment}
        />
      );
    }
  }

  ret =
    relation != null ? (
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="start"
        spacing={0}
      >
        {chip}
        <ChangeCommentDialog
          open={dialogOpen}
          title={tCapitalize("modify_text")}
          text={comment == null ? "" : comment}
          onClose={() => {
            setDialogOpen(false);
          }}
          onChange={function (newValue: string): void {
            changeComment({ relationId: relation.Id, newText: newValue });
            setComment(newValue);
          }}
        />
      </Stack>
    ) : null;

  return ret;
}
