import { Card, CardContent, CardHeader } from "@mui/material";
import React from "react";
import useTranslationService from "../../services/TranslationService";
import SmartAccordion from "../accordion/SmartAccordion";
import GenericInfoComponent from "../others/GenericInfoComponent";
import SmartLink from "../others/SmartLink";
import NavigateToProviderButton from "./../place/parts/NavigateToProviderButton";

interface IScrapeLinkComponentProps {
  scrapeLinkDataObject: any;
}
export default function ScrapeLinkComponent({
  scrapeLinkDataObject,
}: IScrapeLinkComponentProps): JSX.Element | null {
  const { t, tCapitalize } = useTranslationService();
  let ret = <p>{t("no-data-found")}</p>;
  if (scrapeLinkDataObject) {
    const dataString = scrapeLinkDataObject.Data;
    const placeId = scrapeLinkDataObject.PlaceId;
    const dataObject = JSON.parse(dataString);
    // const statusObject = JSON.parse(statusString);
    const name = dataObject !== null ? dataObject.name : "<no name>";
    ret = (
      <Card>
        <CardHeader
          title={name}
          sx={{ pb: 0 }}
          subheader={"Id: " + scrapeLinkDataObject.Id}
        />
        <CardContent sx={{ pb: 0 }}>
          <SmartLink
            href={"/place/" + placeId}
            internalLink={true}
            showAsButton={false}
          >
            {tCapitalize("show-details-for-place-place", { place: placeId })}
          </SmartLink>
          <SmartAccordion header={t("more-info")} defaultExpanded={false}>
            <NavigateToProviderButton scrapeLink={scrapeLinkDataObject} />
            <GenericInfoComponent
              key="generic_info"
              dataObject={scrapeLinkDataObject}
              hide={false}
              open={false}
            />
          </SmartAccordion>
        </CardContent>
      </Card>
    );
  }
  return ret;
}
