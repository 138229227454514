import { LocationOnTwoTone } from "@mui/icons-material";
import React from "react";
import { stripDomainFromUrl } from "../../utils/Utils";
import { SmartChip } from "../chip/SmartChip";

type Props = {
  url: string;
};

export function ProviderChip({ url }: Props) {
  const providerName = stripDomainFromUrl(url);
  return (
    <SmartChip
      icon={<LocationOnTwoTone />}
      text={providerName}
      href={url != null ? url : undefined}
      target="_blank"
      tooltip={url}
    />
  );
}
