import { MenuTwoTone } from "@mui/icons-material";
import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { TitleContext } from "../../TitleContext";
import AuthenticationComponent from "./../../components/auth/AuthenticationComponent";
import { DRAWER_WIDTH } from "../../stuff/StyleConstants";

interface IProps {
  toggleDrawer: any;
  show?: boolean; //optional
  text?: string;
  children?: JSX.Element | JSX.Element[];
}

export default function MainToolbar({
  toggleDrawer,
  show,
  text,
  children,
}: IProps): JSX.Element | null {
  const titleContext = useContext(TitleContext);
  const [titleText, setTitleText] = useState("");

  useEffect(() => {
    if (titleContext && titleContext.title) {
      setTitleText(titleContext.title);
    }
  }, [titleContext]);

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${DRAWER_WIDTH}px)` },
          ml: { sm: `${DRAWER_WIDTH}px` },
          // backgroundColor: "green",
        }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2, display: { sm: "none" } }}
            onClick={toggleDrawer}
          >
            <MenuTwoTone />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {titleText}
          </Typography>
          <AuthenticationComponent />
        </Toolbar>
        {/* <Drawer
      anchor='left'
      open={drawerOpen}
      onClose={toggleDrawer(false)}
    >
      {drawerContent}
    </Drawer> */}
      </AppBar>
    </>
  );
}

// function _createMenuEntry2(x: { mainMenu: boolean; link: string; icon: any; tooltip: any; name: string; }) {
//   if (!x.mainMenu) {
//     return null;
//   }
//   return (<Button
//     key={x.link}
//     variant="contained"
//     href={"/" + x.link}
//   >{x.name}</Button>);
// }
