import { useGetPlacesJoinFilteredQuery } from "../api/PlacesApiSlice";
import { IPlace } from "../stuff/AppModelTypes";
import { updateObjectsInArrayWithArray } from "../utils/ArrayUtils2";

export function usePlacesServiceFromPlaceIds(placeIds: string[]) {
  const placeIdsForQuery = [];
  let skip = false;
  if (placeIds == null || placeIds.length < 1) {
    skip = true;
  }
  for (let index = 0; index < placeIds.length; index++) {
    placeIdsForQuery.push("" + placeIds[index]);
  }
  const queryPros = {
    ids: placeIdsForQuery,
  };
  const { data: placeQueryResult } = useGetPlacesJoinFilteredQuery(queryPros, {
    skip,
  });
  let placeObjects: IPlace[] = [];
  let placeObjectsWithScrapeLinks: IPlace[] = [];
  if (placeQueryResult) {
    if (placeQueryResult.records && placeQueryResult.records[0]) {
      placeObjects = placeQueryResult.records;
      placeObjectsWithScrapeLinks = placeObjects.filter((place) => {
        return place.ScrapeLinks != null && place.ScrapeLinks.length > 0;
      });
    }
  }
  const ret = { placeObjects, placeObjectsWithScrapeLinks };
  return ret;
}

export function useCompletePlaces(places: IPlace[]): any[] {
  let placeIds: string[] = [];
  if (places != null) {
    for (let index = 0; index < places.length; index++) {
      const onePlace = places[index];
      if (onePlace.Name == null) {
        placeIds.push(onePlace.Id);
      }
    }
  }
  const { placeObjects } = usePlacesServiceFromPlaceIds(placeIds);
  // const ret = [...places, ...placeObjects];
  const ret = updatePlaceObjects(places, placeObjects);
  return ret;
}

function updatePlaceObjects(oldPlaces: IPlace[], newPlaces: IPlace[]): any[] {
  function checkEqual(a: { Id: string }, b: { Id: string }) {
    if (a.Id + "" === b.Id + "") {
      return true;
    }
    return false;
  }
  updateObjectsInArrayWithArray(oldPlaces, newPlaces, checkEqual);
  return oldPlaces;
}
