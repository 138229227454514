import React from "react";
import { SWIMMING_POOL } from "../../../api/FeaturesConstants";
import { FeatureChip, FeatureChipSubtypeProps } from "./FeatureChip";

export function SwimmingChip({ feature }: FeatureChipSubtypeProps) {
  return (
    <FeatureChip
      // textKey="swimming"
      // icon={<PoolTwoTone />}
      feature={feature}
      featureConst={SWIMMING_POOL}
    />
  );
}
