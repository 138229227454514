import { IPlace } from "../stuff/AppModelTypes";
import { useGetPlaceJoinedQuery } from "../api/PlacesApiSlice";
import { useGetScrapeLinksForPlace } from "./ScrapeLinksService";

function usePlaceService(placeId: string): { placeObject: IPlace | null } {
  let ret = null;
  let placeObject: IPlace | null = null;
  const returnNullAtTheEnd: boolean = placeId == null;
  placeId = "" + placeId;

  const { data: placeQueryResult } = useGetPlaceJoinedQuery(placeId);
  // placeObject = {
  //   Id: placeId,
  // };
  if (placeQueryResult) {
    if (placeQueryResult.records && placeQueryResult.records[0]) {
      placeObject = placeQueryResult.records[0];
    }
  }
  if (returnNullAtTheEnd) {
    placeObject = null;
  }
  ret = { placeObject };
  // console.log("usePlaceService: " + placeId + " -> " + JSON.stringify(ret));
  return ret;
}

export default usePlaceService;

export const SKIP_PLACE: IPlace = {
  Id: "-",
};

export function useCheckIfPlaceIsDeleted(place: IPlace) {
  const scrapeLinks = useGetScrapeLinksForPlace(place);
  const deleted: boolean = scrapeLinks != null && scrapeLinks.length < 1;
  return deleted;
}
