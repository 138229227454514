import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useEffect, useState } from "react";
import { equalObject } from "../../utils/Utils";
import { SmartList } from "./SmartList";

const PAGE_SIZE = 20;

type Props = {
  query: any; // query function
  renderObject: (object: any, index?: number) => React.ReactNode;
  queryArguments: any;
  pageSize?: number;
  updateCount?: (count: null | number) => void;
};

export function SmartQueryList({
  query,
  renderObject,
  queryArguments,
  pageSize = PAGE_SIZE,
  updateCount,
}: Props) {
  const [queryArgsInternal, setQueryArgsInternal] = useState<any>();
  const [model, setModel] = useState<any[]>([]);
  const [sentCount, setSentCount] = useState<number>();
  const { data } = query(queryArgsInternal ?? skipToken);

  useEffect(() => {
    const newObj = { ...queryArguments };
    newObj.pageSize = pageSize;
    newObj.page = 1;
    if (!equalObject(queryArgsInternal, newObj)) {
      setQueryArgsInternal(newObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryArguments]);

  // adds the new records to the model state array
  useEffect(() => {
    if (data != null && data.records != null) {
      const newModel =
        queryArgsInternal.page === 1
          ? [...data.records]
          : [...model, ...data.records];
      setModel(newModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (data != null && data.results != null) {
      const newCount = data.results;
      if (updateCount != null && newCount !== sentCount) {
        updateCount(newCount);
        setSentCount(newCount);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  function handleEndReached() {
    if (queryArgsInternal != null) {
      if (
        queryArgsInternal.page != null &&
        data != null &&
        data.results != null
      ) {
        let howManyAreMissing = data.results - model.length;
        if (
          queryArguments != null &&
          queryArguments.maxResults != null &&
          queryArguments.maxResults > 0
        ) {
          const diff = queryArguments.maxResults - model.length;
          if (diff < howManyAreMissing) {
            howManyAreMissing = diff;
          }
        }
        if (howManyAreMissing > 0) {
          const oldPage = queryArgsInternal.page;
          const newQueryProps = { ...queryArgsInternal };
          newQueryProps.page = oldPage + 1;
          if (howManyAreMissing < PAGE_SIZE) {
            newQueryProps.pageSize = howManyAreMissing;
          }
          setQueryArgsInternal(newQueryProps);
        }
      }
    }
  }

  function handleRenderContent(index: number, object: any) {
    return renderObject(object, index);
  }

  return (
    <>
      <SmartList
        data={model}
        endReached={handleEndReached}
        renderComponent={handleRenderContent}
      />
    </>
  );
}
