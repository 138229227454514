import React from "react";
import SmartAccordionLazy from "../accordion/SmartAccordionLazy";
import SmartEmbed from "./SmartEmbed";

interface IProps {
  url: string;
  header?: string;
}

function SmartEmbedLazy(props: IProps): JSX.Element | null {
  const header = props.header != null ? props.header : props.url;
  function createContent(): JSX.Element | JSX.Element[] {
    return <SmartEmbed url={props.url} />;
  }

  return (
    <SmartAccordionLazy
      key={"SmartAccordionLazy_" + props.url}
      header={header}
      childrenCallback={createContent}
    />
  );
}

export default SmartEmbedLazy;
