import React from "react";
import useTranslationService from "../../../services/TranslationService";
import { SmartChip } from "../../chip/SmartChip";
import { FeatureConst } from "../../../api/FeaturesConstants";

type Props = {
  featureConst: FeatureConst;
};

export function GenericFeatureChip({ featureConst }: Props) {
  const { tCapitalize } = useTranslationService();
  const text = tCapitalize(featureConst.translationKey);
  let tooltip = text;
  let icon = null;
  if (featureConst.icon != null) {
    icon = React.createElement(featureConst.icon);
  }
  return (
    <SmartChip color="success" icon={icon} text={text} tooltip={tooltip} />
  );
}
