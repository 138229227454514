import { Card, CardContent, CardHeader, Stack } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import LeafletMapComponent from "../../components/maps/LeafletMapComponent";
import PlaceLarge from "../../components/place/PlaceLarge";
import { StyledContent } from "../../components/styles/styled.Content";
import usePlaceService from "../../services/PlaceService";
import { COLOR_BACKGROUND_DELETED } from "../../stuff/StyleConstants";

const MapContent = styled(StyledContent)`
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
`;

export function MapPage() {
  const routerParams = useParams();
  const [placeId, setPlaceId] = useState(null);
  const ups = usePlaceService(placeId);
  let placeObject = null;
  if (ups.placeObject != null && ups.placeObject.Id != null) {
    placeObject = ups.placeObject;
  }
  const [placeObjectForDetails, setPlaceObjectForDetails] =
    useState(placeObject);
  const [placeComponent, setPlaceComponent] = useState(null);
  // const [initialLoc, setInitialLoc] = useState(null);

  useEffect(() => {
    if (routerParams != null && routerParams.id != null) {
      setPlaceId(routerParams.id);
    }
  }, [routerParams]);

  // set/update place component when the active place changes
  useEffect(() => {
    let pc = null;
    if (placeObjectForDetails != null && placeObjectForDetails.Id != null) {
      let sx = { flexGrow: 1 };
      const placeDeleted =
        placeObjectForDetails.ScrapeLinks != null &&
        placeObjectForDetails.ScrapeLinks.length < 1;
      if (placeDeleted) {
        sx.background = COLOR_BACKGROUND_DELETED;
      }
      pc = (
        <Card sx={sx}>
          <CardHeader title={placeObjectForDetails.Name} />
          <CardContent>
            <PlaceLarge
              placeObject={placeObjectForDetails}
              showTitle={false}
              hideFlyTo={true}
              showInfo={checkIfInfoVisible()}
            />
          </CardContent>
        </Card>
      );
    }
    setPlaceComponent(pc);
  }, [placeObjectForDetails]);

  function handlePlaceChangedFromMap(place) {
    const pid = place?.Id;
    setPlaceObjectForDetails(place);
    const url = pid != null ? "/map/" + pid : "/map";
    window.history.replaceState({}, "", url);
  }

  const mapComponent = useMemo(() => {
    if (placeId != null) {
      sessionStorage.setItem("flyToPlaceId", placeId);
    }
    return (
      <MapContent key={"MapContent_" + placeId}>
        <LeafletMapComponent
          key={"LeafletMapComponent" + placeId}
          storageKeyCurrentBounds="MapPage_currentBounds"
          onActivePlaceChanged={handlePlaceChangedFromMap}
        />
      </MapContent>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeId]);

  return (
    <Stack
      key={"MapPage_" + placeId}
      direction="row"
      flexWrap="wrap"
      useFlexGap
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >
      {mapComponent}
      {placeComponent}
    </Stack>
  );
}
function checkIfInfoVisible() {
  const infoFromStorage = localStorage.getItem("info");
  if (infoFromStorage != null && JSON.parse(infoFromStorage) === true) {
    return true;
  }
  return false;
}
