import { Stack } from "@mui/material";
import React from "react";
import { IPlacelist } from "../../stuff/AppModelTypes";
import { RenamePlacelistChip } from "../action_chips/RenamePlacelistChip";
import { AccessChip } from "./AccessChip";

type Props = {
  placelist: IPlacelist;
};
export function PlacelistInfo({ placelist }: Props) {
  let ret = null;

  if (placelist != null) {
    ret = (
      <Stack
        key="upper_stack"
        display="inline-flex"
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        alignContent="flex-start"
        spacing="4pt"
        flexWrap="wrap"
        flexGrow={1}
        flexShrink={1}
        useFlexGap
        flexBasis="0"
      >
        <AccessChip placelist={placelist} />
        <RenamePlacelistChip placelist={placelist} />
      </Stack>
    );
  }
  return ret;
}
