import {
  Button,
  FormControlLabel,
  IconButton,
  Select,
  Switch,
} from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import SmartTooltip from "../others/SmartTooltip";
import ISmartAction from "./ISmartAction";
import SmartDialog from "../dialogs/SmartDialog";

const TEXT_BUTTONS = false;
const SimpleButton = styled(Button)({});

interface IProps extends ISmartAction {
  showLabel?: boolean;
}

function SmartButton({
  text,
  shortText,
  tooltipPlacement,
  onMouse,
  disabled,
  children,
  dialogProps,
  selected,
  value,
  showLabel = true,
}: IProps): JSX.Element {
  const [isDialogOpen, setDialogOpen] = useState(false);
  function _handleMouse(eObj: any) {
    if (dialogProps != null) {
      if (!isDialogOpen) {
        setDialogOpen(true);
      }
    }
    if (onMouse != null) {
      onMouse(eObj);
    }
  }
  if (shortText == null) {
    shortText = text;
  }
  let button;
  if (Array.isArray(children)) {
    button = (
      <Select size="small" value={value} onChange={_handleMouse}>
        {children}
      </Select>
    );
  } else {
    if (selected === undefined) {
      if (TEXT_BUTTONS) {
        button = (
          <SimpleButton
            sx={{ textTransform: "none" }}
            onClick={_handleMouse}
            size="medium"
            disabled={disabled}
            startIcon={children}
            variant="outlined"
          >
            {text}
          </SimpleButton>
        );
      } else {
        button = (
          <IconButton size="medium" disabled={disabled} onClick={_handleMouse}>
            {children}
          </IconButton>
        );
      }
    } else {
      button = (
        <Switch
          // color="default"
          checked={selected}
          onChange={_handleMouse}
          disabled={disabled}
        />
      );
    }
  }

  let retButton;
  if (TEXT_BUTTONS) {
    retButton = button;
  } else if (showLabel === true) {
    retButton = (
      <SmartTooltip title={text}>
        <span>
          <FormControlLabel
            value={value}
            control={button}
            label={shortText}
            labelPlacement="bottom"
            disabled={disabled}
            sx={{ mx: 0 }}
          />
        </span>
      </SmartTooltip>
    );
  } else {
    retButton = (
      <>
        <SmartTooltip
          // open={openTooltip}
          placement={tooltipPlacement ? tooltipPlacement : "bottom"}
          title={text}
        >
          <span>{button}</span>
        </SmartTooltip>
      </>
    );
  }
  if (dialogProps != null) {
    const onOk = dialogProps.onOk;
    const onClose = dialogProps.onClose;
    function handleOk(result: any) {
      if (onOk != null) {
        onOk(result);
      }
      setDialogOpen(false);
    }
    function handleClose() {
      setDialogOpen(false);
      if (onClose != null) {
        onClose();
      }
    }
    dialogProps.onOk = onOk != null ? handleOk : undefined;
    dialogProps.onClose = handleClose;
    return (
      <>
        {retButton}
        <SmartDialog dialogProps={dialogProps} open={isDialogOpen} />
      </>
    );
  } else {
    return retButton;
  }
}

export default SmartButton;
